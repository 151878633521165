/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300&family=Roboto:wght@100;300;400;500;700&display=swap');
html {
	font-family: 'Futura PT';
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*background-color: #121212;*/
    background-color:#070101;
    font-family: 'Poppins', sans-serif;
}
.logg #otpless{
    background-color: transparent;
    padding: 0;
    margin: 10px 22% 0 22%;
    align-content:center;
    justify-content: center;
}
.ticket{
    padding: 10px 10%;
    width: 80%;
}
.ticket h1{
    color: #fff;
    font-size: 20px;
    padding: 10px 0;
}

.loader{
    background-color: #0A1823;
    border:1px solid #ccc;
    width: 100%;
    height: 100vh;
    position:fixed;
    top:0;
    right: 0;
    opacity: .6;
    z-index: 9999999;
}
.loader.hide{
display: none;
}
.loader::before{
    opacity: .4;
}
@keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  .spin{
    opacity: 1;
  }
  .spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    z-index: 999999;
    content: "";
    height: 40px;
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }

@font-face {
    font-family: 'Futura PT';
    src: url('assets/fonts/FuturaPT-Medium.woff2') format('woff2'),
        url('assets/fonts/FuturaPT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Futura PT Demi';
    src: url('assets/fonts/FuturaPT-Demi.woff2') format('woff2'),
        url('assets/fonts/FuturaPT-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Bold.ttf') format('ttf'),
        url('assets/fonts/Montserrat-Bold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Futura PT Extra';
    src: url('assets/fonts/FuturaPT-ExtraBold.woff2') format('woff2'),
        url('assets/fonts/FuturaPT-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('assets/fonts/FuturaPT-Book.woff2') format('woff2'),
        url('assets/fonts/FuturaPT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('assets/fonts/FuturaPT-Light.woff2') format('woff2'),
        url('assets/fonts/FuturaPT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('assets/fonts/FuturaPT-Heavy.woff2') format('woff2'),
        url('assets/fonts/FuturaPT-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('assets/fonts/FuturaPT-Bold.woff2') format('woff2'),
        url('assets/fonts/FuturaPT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
body{
    overflow-x: hidden;
}

p {
	padding: 0px; 
	margin: 0px;
	color: #535353;
	font-size: 16px;
}

a {
	padding: 0px; 
	margin: 0px; 
	outline: none;
}

ul { 
	list-style: none; 
	padding: 0px; 
	margin: 0px;
}

ul li { 
	padding: 0px; 
	margin: 0px;
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
	padding: 0px; 
	margin: 0px;
}

a:hover { 
	outline: none; 
	text-decoration: none;
}
.player{
    width: 80%;
    margin: 0px 10%;
    color: #fff;
    border:1px solid #ccc;
    height: 800px;
    border-radius: 12px;
}
a:active { 
	outline: none; 
	text-decoration: none;
}
button:focus { 
	outline: none; 
	text-decoration: none;
}
a:focus { 
	outline: none; 
	text-decoration: none;
}

a {
	-webkit-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

a:hover {
	text-decoration: none!important;
}

h1,
h2,
h3,
h4,
h5 {
	padding: 0;
	margin: 0;
}


.content_popup{
    background-color: #111;
    width: 400;
    height: auto;
    align-content: center;
    justify-content: center;
    margin: 15% 30%;
    color: #fff;
    padding: 20px;
    border-radius: 12px;
    position: absolute;
    z-index: 999999999;
}
.content_popup h2{
    color:#D90917;
    font-size: 42px;
    text-align: center;
    margin: 20px 0px;
}
.content_popup p{
color: #ccc;
text-align: center;
width: 70%;
font-size: 18px;
margin: 30px 15% 10px 15%;
}
.content_popup p.head{
    color: #fff;
    font-size: 22px;
    width: 90%;
    margin: 0px 5%;
}
.content_popup .buttons{
    display: inline-flex;
    width: auto;
    margin: 20px 25%;
    width:50%;
}
.content_popup .buttons .btn{
    background-color: #D90917;
    color: #fff;
    padding: 10px 20px;
    margin: 0px 0px 0px 10px;
    align-content: center;
    opacity: .9;
}
.content_popup .buttons .btn:hover{
    opacity: 1;
}
.content_popup .buttons .btn:nth-child(1){
    
    background-color: #fff;
    color: #D90917;
    opacity: .9;
}
.content_popup .buttons .btn:nth-child(1):hover{
    opacity: 1;
}




.install{
border:1px solid #222;
border-radius: 12px;
width: 36%;
bottom: 0;
height: 110px;
color: #fff;
padding: 10px 10px;
background-color: #070101;
display: inline-flex;
position: fixed;
display: none;
z-index: 999999;
}
.install .fa{
    width: 5%;
    margin: 5px 0px;
}
.install img{
width: 20%;
height: 35px;
margin: 0px 5px;
}

.install p{
    width: 50%;
    color: #fff;
    padding: 2px 5px;
    font-size: 18px;
    line-height: 20px;
    display: inline-block;
}
.install p span{
    font-size: 14px;
    margin-top: 0px;
    color:#ccc;
}
.install p span.icons{
    display: inline-flex;
    color:yellow;
    
}
.install p span.icons .fa{
    padding: 0px 25px 0px 0px;
    font-size: 17px;
    margin: 0;
}
.install a{
    width: 25%;
    border:1px solid #D90917;
    border-radius: 12px;
    margin:5px 0px;
    text-align: center;
    padding: 10px;
    height :45px;
    background-color: #D90917;
    color: #fff;
    font-size: 18px;
}
.clearfix:before, .clearfix:after {
	content: " ";
	display: table;
}
.clearfix:after {
	clear: both;
}
.container.add{
    width: 100%;    
    margin: 40px 0px 40px 0px;
    background-color: #070101;
}
.container.add .col-md-6:nth-child(1){
    width: 50%;
    height: 100%;
    margin:0 -8% 0 0;
    padding: 0px 0 0 20px;
    z-index: 9999;
}
.container.add .col-md-6:nth-child(1) img{
    width: 90%;
    height: auto;
    padding: 0;
    margin: 0 0% 0 10%;
}
.container.add .col-md-6:nth-child(2){
display: inline-block;
background-image: url('./assets/Icons/Rectangle\ 5.png');
background-size: cover;
padding: 0px 20px 40px 6%;
height:100%;
margin:4% 0;
z-index: 1;
}

.container.add .col-md-6 h2{
font-family: sans-serif;
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 50px;
color: #FFFFFF;
padding: 40px 0px 0px 0px;
float: left;
}
.add .col-md-6 p{
    color: #fff;
    margin: 130px 0px 0px 0px;
    padding: 10px 0px;
    font-size: 22px;
    font-weight: normal;
}
.add .col-md-6 h3{
    color: #fff;
    margin: 10px 0px 0px 0px;
    padding: 10px 0px;
    font-size: 26px;
}
.add .col-md-6 hr{
    width: 120px;
    height: 5px;
    margin: 10px 0px 0px 0px;
}
.add .col-md-6 .images{
    padding: 10px 0px 0px 0px;
    display: inline-flex;
}

.add .col-md-6 .images a img{
    padding: 0;
    margin: 0 10px 0px 0px;
    width: auto;
    height: 90px;
}
.frequent{
    padding: 30px 12%;
    width: 100%;
    height: auto;
    background-color: transparent;
    align-content: center;
    justify-content: center;
}
.frequent h1{
    text-align: center;
    color: #fff;
    padding: 10px;
}
.frequent h3{
    text-align: center;
    color: #fff;
    padding: 10px;
    margin-bottom: 40px;
}
.frequent .row{
    padding: 0px;
}
.frequent .row .col-md-4 {
    padding: 0px;
}
.frequent .row .col-md-4 .ask{
    width: 100%;
    margin:20px 0% 10px 0%;
    background-image: url('./assets/Icons/Rectangle\ 474.png');
    background-size: cover;
    padding: 10% 30px;
    border-radius: 12px;
    height: 350px;
}
.frequent .row .col-md-4 .ask p{
    color: #ccc;
    padding: 0;
}
.frequent .row .col-md-4 .ask h1{
    padding: 10px 0;
    text-align: left;
    font-size: 32px;
}
.frequent .row .col-md-4 .ask .playbtn{
    width: 50%;
    margin: 20px 45% 10px 0%;
    color :#fff;
    background-color: #D90917;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    padding: 13px 0px;
}
.frequent .row .col-md-8{
    padding: 10px;
    display: inline-block;
    margin: 0px 0%;    
}
.frequent .row .col-md-8 .freq-child{
    display: inline-flex;
    width: 90%;
    padding: 0;
    margin: 15px 5% 0 5%;
}

.frequent .row .col-md-8 .hidden{
    height: 0px;
    width: 90%;
    padding: 0px;
    margin: 0px 5% 0 5%;
    color:#ccc;
    overflow: hidden;
    transition: .3s linear;
    background-color:rgba(208, 8, 32, 0.1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.frequent .row .col-md-8 .hidden p{
padding: 10px 15px;
color: #ccc;
}
.frequent .row .col-md-8 span{
    width: 90%;
    color: #fff;
    padding: 13px 20px;
    background-image: url('./assets/Icons/Rectangle\ 28.png');
    background-size: cover;
}
.frequent .row .col-md-8 .fa{
    width: 10%;
    border-radius: 5px;
    padding: 15px 20px;
    color: #fff;
    background-color:rgba(208, 8, 32, 0.1);
}
.frequent .row .col-md-8 .fa-minus{
    display: none;
}
.blur#D8000F{
    height: 30px;
    background-color: #121212;
    margin: 0px;
    width: 90%;
    margin: 0px 5% 0px 5%;
    z-index: 99;
    position: absolute;
}
.trailer{
    margin:0px 5%;
    width: 90%;
    padding: 10px 5px;
    height: auto;
}

.trailer h1{
    color: #fff;
    font-size: 24px;
    padding: 0% 0px 0px 0px;
    position: absolute;
}
.trailer p{
    padding: 2% 0 0 0;
    color: #ccc;
    font-size: 18px;
    position: absolute;
}
.trailernews{
    margin:20px 0 20px 0;
    padding: 40px 0px;
    width: 100%;
    background-image: url('./assets/Icons/Group\ 66365.png');
    background-size: cover;
    align-content: center;
    justify-content: center;
    height: auto;
}
.trailernews h1{
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    margin: 10px 0px 0px 0px;
    opacity: 1;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

}
.trailernews p{
    margin: 5px 0px 10px 0px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 200;
}
.trailernews input{
    padding: 12px 20px;
    width: 40%;
    margin: 15px 30% 0 30%;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0396) 0%, rgba(255, 255, 255, 0.1008) 100%);
    outline: none;
    color: #fff;
    border: 1px solid #FFFFFF;
}
.trailernews input::before{
    border-top: 2px solid #D8000F;
    width: 100px;
}
.trailernews button{
    width: 15%;
    margin: 20px 42.5% 10px 42.5%;
    color :#fff;
    background-color: #D90917;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    padding: 13px 0px;

}
.clear {
	clear: both;
	line-height: 0;
	font-size: 0;
}
.manageuser{
    margin: 2px 0px;
    z-index: 999999;
}
.fa-user, .fa-sign-out{
    color: #fff;
    font-size: 18px;
    padding: 0px 8px;
}
.manageuser button{
    color: #fff;
    font-size: 18px;
    font-family: 'Futura PT';
}
.small{
    color:#D8000F;
    font-size:13px;
    padding: 0px 0px 5px 0px;
}
.container {
	width: 100%;
	max-width: 1600px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;

}

.episode_playback,.trailer_playback{
    width:80%;
    margin:0px 10%;
}

header {
    width: 100%;
    position: relative;
    height: 70px;
    background: transparent;
    top: 0;
    left: 0;
    padding: 10px 0;
}

header a img{
	max-width: 100%;
    width: 150px;
    height: auto;
    margin: 0px 0px 0px 2%;
}

a.logo span{
	font-size: 28px;
	color: #fff;
	font-weight: 500;
	font-family: 'Trajan Pro';
}


.category_block.active{
    padding: 20px 30px;
    width: 100%;
    height: 50vh;
    margin: 10px 0px 0px 0px;
    background-blend-mode: color-burn;
    background-image: linear-gradient(to right,#ccc,#555);
    border-radius: 6px;
    display: inline-flex;
    box-shadow: 0 3px 10px #555;
}

.category_block{
   display: none;
}
header .category_block.active h1{
    margin: 10px;
    font-size: 18px;
}
header .category_block.active .row{
    height: 100%;
}

header .category_block.active .row .col-md-6:nth-child(2){
    border-left: 2px solid #ccc;
    color: #fff;
    padding: 0px 30px;
    display: inline-block;
}
header .category_block.active .row .col-md-6:nth-child(2) li{
    list-style-type: none;
    font-size: 22px;
    filter: none;
    padding: 5px 10px;
}
header .category_block.active .row .col-md-6:nth-child(1) .category_options{
    width: 25%;
    height: auto;
    border-radius: 6px;
    background-color: #D90917;
    color:#fff;
    padding: 20px 0px;
    margin: 10px;
    align-content: center;
    justify-content: center;
    display: inline-flex;
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
}
.mobile_primary{
    display: none;
}
header .category_block.active .row .col-md-6:nth-child(1) .category_options .co{
    color: #fff;
}
header .category_block.active .row .col-md-6:nth-child(1) .category_options:hover{
    transition: .2s linear;
    transform: translateY(-5px);
}
.category_small_screen{
    display: none;
}
.right_nav{
	width: 55%;
	float: right;
    padding: 0px 3% 5px 0px;
    margin: 0;
    height: 59px;
    border-bottom: 3px solid #333;
    background-color: rgba(0, 0, 0, 0.3);
}
header .fa{
    font-size: 16px;
}
.header_btn {
    float: right;
    margin-top: 5px;
    padding: 0 0px 0px 0px;
    margin-right: 1%;
    margin-left: 3%;
}

.header_btn ul {
    padding: 0;
    margin: 0;
    display: inline-block;
    overflow: hidden;
}

.header_btn ul li {
    float: left;
    width: auto;
    padding: 0 5px;
    display: inline-flex;
}
.header_btn ul li a.ho:hover {
    background-color: #D90917;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
}
.header_btn ul li a.ho{
    margin-right: 0px;
}
.header_btn ul li a {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    line-height: 40px;
    width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    padding: 0;
    margin: 3px 90px 0 0;
}
.header_btn ul li a img{
    width: 100%;
    height: 45px;
}
.header_search {
    float: right;
    margin-right: 3%;
    border-bottom: 2px solid #333;
    margin-top: 15px;
    padding: 0 0px 14px 0px;
    cursor: pointer;
    font-size: 16px;
}
.click_to_subsc {
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    float: right;
    margin-right: 6%;
    letter-spacing: 1px;
    margin-top: 15px;
    border-bottom: 2px solid #333;
    padding: 0 0px 17px 0px;
    text-decoration: none;
}
.click_to_subsc.active {
    color: #D8000F;
    border-bottom: 2px solid #D8000F;
}
.click_to_subsc:hover ,.header_search:hover {
    border-bottom: 2px solid #D8000F;
    color: #D8000F;
}
.click_to_subsc .fa {
    margin-right: 5px;
}

/*css for upcoming slider*/

.banner .banner_col1 {
    height: auto;
    width: 100%;
    padding:0;
    margin: 0;
}
.banner .banner_col1 img{
    width: 70%;
    margin: -60px 15% 0 15%;
}
.banner .banner_col1 .banner_btm{
    display: inline-flex;
    width: 70%;
    height: 130px;
    margin: 10px 15% 0 15%;
}
.banner .banner_col1 .banner_btm h2{
    color: #fff;
    padding: 10px 0 0 0%;
    margin: 25px 0 0 0;
    width: 70%;
    font-size: 26px;
}
.banner .banner_col1 .banner_btm a{
    width: 30%;
    margin: 0;
}
.banner .banner_col1 .banner_btm a img{
    width: 90%;
    margin: -60px 5% 0 5%;
    padding: 0;
}
.banner .banner_col2 .upcoming {
    width: 100%;
    padding: 20px 0;
    height: 600px;
    margin:-20px 0 0 0;
}

.banner .banner_col2 .upcoming .upinner{
    width: 100%;
    display: inline-flex;
}
.banner .banner_col2 .upcoming .upinner .upinnerch{
    margin: 0;
}
.banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(1){
    width: 40%;
}
.banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(2){
    width: 60%;
    display: inline-block;
    padding: 10px 15px;
}
.banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(1) img{
    margin: -20px 2% 0 8%;
    border-radius: 12px;
    width: 90%;
    height: 360px;
}
.banner .banner_col2 .upcoming .upinner .upinnerch h2{
    font-size: 32px;
    padding: 40px 0 5px 0;
    font-weight: bold;
}

.banner .banner_col2 .upcoming .upinner .upinnerch span.date{
    position: absolute;    
    background-image: url('./assets/Icons/Rectangle\ 1218.png');
    background-size: cover;
    width: 160px;
    height: 60px;
    text-align: center;
    padding: 5px 10px;
    font-size: 10px;
    right:0;
    margin: -21px -24px 0 0px;
    color: #fff;
}
.banner .banner_col2 .upcoming .upinner .upinnerch p{
    font-size: 16px;
    font-weight: normal;
    padding: 10px 0 5px 0;
    color: #ccc;
}
.banner .banner_col2 .upcoming .swiper-slide{
    color:#fff;
    background: transparent;
    margin: 160px 0 0 0;
    background-color: #1C1C1C;
    border-radius: 12px;
    height: 360px;
    opacity: 1;
    transition: .3s linear;
  }
.banner .banner_col2 .upcoming .swiper-slide-active {
    color:#fff;
    margin: 80px 0;
    border-radius: 12px;
    z-index: 99;
    background-color:#302222;
    height: 360px;
    opacity: 1;
    transition: .3s linear;
}
.banner .banner_col2  .swiper-button-prev{
    position: absolute;
    background-image: url('./assets/Icons/Left arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 35px;
    width:35px;
    top:95%;
    left:68%;
    padding: 10px; 
    overflow:hidden;
  }
  .banner .banner_col2 .swiper-button-next{
    position: absolute;
    background-image: url('./assets/Icons/right arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 35px;
    width:35px;
    top:95%;
    right:27%;
    padding: 10px; 
    overflow:hidden;
  }

.banner .banner_col2 .bottom{
    top:70%;
    padding: 15px 0px;
    width: 40%;
    margin: 0px 0 0 26%;
    position: absolute;
    display: inline-flex;
}
.banner .banner_col2 .bottom p{
    font-size: 18px;
    width: 12%;
    display: inline-flex;
}
.banner .banner_col2 .bottom .line{
    width: 88%;
    height: 2px;
    margin: 15px 0;
    background-color: #fff;
}
.banner .banner_col2 .upcoming .upinner .upinnerch .buttons{
    display: flex;
    margin: 10px 0 0 0;
    cursor: pointer;
}

.banner .banner_col2 .upcoming .upinner .upinnerch .buttons .fa{
    font-size: 16px;
    padding: 8px 10px;
    color: #D8000F;
    margin: 0 10px 0 0;
    border-radius: 2px;
    opacity: 1;
    background-color: #fff;
}
.banner .banner_col2 .upcoming .upinner .upinnerch .buttons .playbtn{
    background-color: #D8000F;
    display: inline-flex;
    border-radius: 2px;
    padding: 5px 0px 5px 5px;
    letter-spacing: 1px;
    height: 45px;
    width: 48%;
    margin:0 2% 0 0;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
}
.banner .banner_col2 .upcoming .upinner .upinnerch .buttons span{
    padding: 5px 0 0 0;
    font-size: 16px;
}
.banner .banner_col2 .upcoming .upinner .upinnerch .buttons .watchbtn{
    background-color:  rgba(11, 5, 5, 0.1);
    display: inline-flex;
    border-radius: 2px;
    padding: 5px 0px 5px 5px;
    letter-spacing: 1px;
    height: 45px;
    width: 48%;
    margin:0 10px 0 0;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
}
.banner .banner_col2 .upcoming .upinner .upinnerch .buttons .watchbtn .fa{
    color: #fff;
    background-color: #D90917;
}


.banner .banner_col1 {
    height: auto;
    width: 100%;
    padding:4% 5% 6% 5%;
    margin: 0px 0% 0px 0%;
}.banner .banner_col1 .homeslider{
    width: 100%;
    margin: 0 0% 0 0%;
    display: inline-flex;    
}

.banner .banner_col1 .homeslider .detailed{
    width: 40%;
    padding: 60px 20px 20px 5%;
    margin: 0 0 0 0%;
}
.banner .banner_col1 .homeslider .detailed p{
    color: #ccc;
    font-size:17px;
    padding: 10px 0 20px 0;
}
.banner .banner_col1 .homeslider .detailed h2{
    color: #fff;
    font-size: 35px;
    padding: 0px 0 0 0;
}
.banner .banner_col1 .homeslider .detailed .head{
    font-size: 20px;
    padding: 0;
}
.banner .banner_col1 .detailed .head span{
    font-size: 22px;
    color:#D8000F;
}
.banner .banner_col1 .detailed .buttons{
    display: flex;
}
.banner .banner_col1 .detailed .buttons a{
    color: #fff;
    text-decoration: none;
}

.banner .banner_col1 .detailed .buttons .fa{
    font-size: 18px;
    padding: 8px 10px;
    color: #D8000F;
    margin: 0 10px 0 0;
    border-radius: 2px;
    opacity: 1;
    background-color: #fff;
}
.banner .banner_col1 .detailed .buttons .playbtn{
    background-color: #D8000F;
    display: inline-flex;
    border-radius: 2px;
    padding: 8px 0px 8px 8px;
    height: 53px;
    width: 255px;
    margin:0 10px 0 0;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
}
.banner .banner_col1 .detailed .buttons span{
    padding: 5px 0 0 0;
}
.banner .banner_col1 .detailed .buttons .watchbtn{
    display: inline-flex;
    padding: 8px 0px 8px 8px;
    height: 53px;
    width: 255px;
    background-color: transparent;
    background-image: url('./assets/Icons/Rectangle\ 1214\ \(1\).png');
    background-size: cover;
    margin:0 10px 0 0;
    
}
.banner .banner_col1 .homeslider .swiper {
    padding: 0px 50px 0px 40px;
    margin: 0 0% 0 0%;
    width:60%;
    height:500px;
    display: flex;
}  
 
.banner .banner_col1 .swiper-slide {
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  width: 100%;
  height: 440px;
  background-size: cover;
  }
  .banner .banner_col1 .swiper-slide img{
    width: 95%;
    height: 100%;
    border-radius: 12px;
}
.banner .banner_col1 .homeslider .bottom{
    top:76%;
    padding: 15px 0px;
    width: 38%;
    margin: 0 0 0 39%;
    position: absolute;
    display: inline-flex;
}
.banner .banner_col1 .bottom p{
    font-size: 18px;
    width: 12%;
    display: inline-flex;
}
.banner .banner_col1 .bottom .line{
    width: 88%;
    height: 2px;
    margin: 15px 0;
    background-color: #fff;
}
 .banner .swiper-button-next::after {
    display: none;
  }
  .banner .swiper-button-prev::after {
    display: none;
  }

  .banner .banner_col1  .swiper-button-prev{
    position: absolute;
    background-image: url('./assets/Icons/Left arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px;
    width:30px;
    top:96%;
    left:80%;
    padding: 10px; 
    overflow:hidden;
  }
  .banner .banner_col1 .swiper-button-next{
    position: absolute;
    background-image: url('./assets/Icons/right arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px;
    width:30px;
    top:96%;
    right:12%;
    padding: 10px; 
    overflow:hidden;
  }
  .banner .banner_col  .swiper-button-prev{
    position: absolute;
    background-image: url('./assets/Icons/Left arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px;
    width:30px;
    top:12%;
    left:94%;
    padding: 10px; 
    overflow:hidden;
  }
  .banner .banner_col .swiper-button-next{
    position: absolute;
    background-image: url('./assets/Icons/right arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px;
    width:30px;
    top:12%;
    right:1%;
    padding: 10px; 
    overflow:hidden;
  }
  .banner .banner_col .swiper-slide {
    height: 200px;
    background: transparent;
    margin: 5% 0 0px 0;
    border-radius: 12px;
  }
  .banner .banner_col .swiper-slide-active {
    border:2px solid #D90917;
  }

  .banner .banner_col  .swiper-pagination-bullet  {
    background-color: #D90917;
    display: none;
 }


 .banner .banner_col .swiper-slide a,.banner .banner_col .swiper-slide-active a{
    width: 100%;
    height: 100%;
 }
  .banner .banner_col .swiper-slide a img{
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    height: 100%;
    border-radius: 12px;
    padding: 0px;
    margin:0px;
  }
  .banner .banner_col .swiper-slide-active a img {
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    height: 100%;
    border-radius: 12px;
  }
  .banner .banner_col #play_btn{
    margin-left: 47%;
    margin-top: -6.5%;
    z-index: 99;
    opacity: 1;
    position: absolute;
  }
  .banner .banner_col #play_btn_trailer{
    width: 80px;
    height:80px;
    margin-left: 47.5%;
    margin-top: -5%;
    z-index: 999999;
    opacity: 1;
    position: absolute;
  }

.banner-content h1 {
    font-size: 80px;
    color: #fff;
    margin-bottom: 20px;
    line-height: 100px;
    width: 100%;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    text-shadow: 0 10px 25px rgb(0 0 0 / 0.70);
    position: relative;
    padding-top: 135px;
}
.inner_banner .banner-content h1 {
    text-transform: uppercase;
}

.banner-content h1:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 124px;
    top: 0;
    left: 0;
    background-size: 100%;
}


/*mobile_menu-end*/
.toggle_search span {
    float: left;
    position: relative;
    top: -5px;
    margin-right: 10px;
}
.toggle_search span img{
    width: 15px;
    height:16px;
}

.toggle_search{
	font-size: 18px;
	font-weight: normal;
	color: #fff;
}

.open.toggle_search_open{
	display: none;
}


.toggle_search_open {
    width: 100%;
    height: 100vh;
    background-color: #D90917f2;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 20px;
    z-index: 99999999;
}
.toggle_search_open_area {
    height: 100%;
    width: 100%;
    margin-top: 64px;
    position: relative;
}
.toggle_search_open_area input[type=text]::placeholder{
	color: #fff;
}
.toggle_search_open_area input[type=text] {
    width: 100%;
    line-height: 60px;
    border: none;
    border-bottom: 2px solid #fff;
    padding: 0 60px 0 20px;
    background-color: transparent;
    font-size: 20px;
    color: #fff;
}
.toggle_close {
    background: url(assets/image/mobile_menu_close_icon.png) 0 0 no-repeat;
    position: absolute;
    top: -57px;
    right: 0px;
    z-index: 9999999999;
    width: 32px;
    height: 35px;
    cursor: pointer;
} 

.toggle_search_open_area input[type=submit] {
    top: 18px;
    right: 20px;
    border: none;
    position: absolute;
    width: 30px;
    height: 30px;
    background: url(assets/image/search_icon.png) 0 0 no-repeat;
    text-indent: -66666px;
}
.toggle_search_open_area input[type=text]:focus{
	outline: none;
}

.banner_subsc_btn span {
    float: left;
    position: relative;
    top: -3px;
    margin-right: 0px;
}

.banner_btn_area{
	width: 100%;
	position: relative;
}
.banner_btn_area:after {
    width: 35%;
    left: -20px;
    background: #D90917;
    background: linear-gradient(90deg, rgba(208,8,32,0) 0%, #D90917 68%);
    height: 2px;
    content: "";
    top: 50%;
    position: absolute;
}
.banner_btn_area:before {
    width: 35%;
    right: -20px;
    background: #D90917;
	background: linear-gradient(-90deg, rgba(208,8,32,0) 0%,#D90917 68%);
    height: 2px;
    content: "";
    top: 50%;
    position: absolute;
}


.banner_subsc_btn {
    font-size: 25px;
    font-weight: normal;
    color: #fff;
    padding: 0 30px;
    line-height: 80px;
    display: table;
    border-radius: 5px;
    margin: 0% auto 0 auto;
    background-color: #D90917;
    text-transform: uppercase;
    position: relative;

}
.banner-content {
    width: 800px;
    margin: 0 auto;
    display: table;
}

.sec_heading{
	width: 100%;
	display: inline-block;
}
.sec_heading h2 {
    font-size: 70px;
    font-weight: normal;
    color: #fff;
    margin: 0 auto;
    display: table;
    position: relative;
    padding-bottom: 55px;
}
.sec_heading h2:after{
	position: absolute;
	content: "";
	width: 669px;
	height: 44px;
	bottom: 0;
	left: 50%;
	margin-left: -334px;
}
.product{
    padding: 0px 10px 0px 10px;
    margin:0;
}
.product_item_list{
    display: inline-flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    transition: 1s linear;
    margin: 0;
    height: 340px;
}
.product_item_list a p{
    color:#fff;
    padding: 5x 10px 0px 10px;
    font-size: 16px;
    position: absolute;
    top:101%;
    display: none;
}
.product_item_list div{
    display: inline-flex;
}
.product_item_list::-webkit-scrollbar{
    display: none;
}
.product_item_list .product_item{
    margin: 0px 10px;
}
.product_item_list.header .product_item{
    margin: 20px 10px  0px 0px; 
}
.product_item_list.header .product_item .product_item_image {
    margin: 0px 10px 0 0;
    background: none;
    padding: 0;
    box-shadow: none;
    width: 200px;
    height: auto;
}
.product_item_list.header .product_item .product_item_image:hover {
    transform: scale(1);
}
.product_item_list.header .product_item .product_item_image .product_item_image_overlay{
    display: none;
    }

.product_item_image{
	width: 190px;
	position: relative;
    height: 250px;
    margin:15px 5px 0px 0px;
}
.product_item_image.upcoming{
	width: 300px;
	position: relative;
    height: 220px;
    margin:10px 5px 0px 5px;
}

.product_item_image img{
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

.product_item_image:hover{
    transform: scale(1.1);
    transition: 0.3s linear;
}
.product_item_image:hover p{
    display: block;
}
.product_item_image1 img{
    border-radius: 12px;
    width: 100%;
    height: 100%;
}
.product_item1 p{
color:#fff;
padding: 15px 0px;
font-size: 22px;
}

.product_item_list div .product_item .product_item_image_overlay {
    display: none; 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    left: 0;
    padding: 15px;
    border-radius: 12px;
}
.product_item_list div .product_item:hover .product_item_image_overlay{
	display: block;
}
.product_category .slick-initialized .slick-slide {
    margin: 0 15px;
}

.product_category_heading{
	width: 100%;
	display: inline-flex;
	margin-bottom: 10px;
}
.product_category_heading h4{
    width: 90%;
	font-size: 25px;
	font-weight: 500;
	color: #fff;
    padding: 0px 25px 0px 15px;
    margin: 0;
}
.product_category_heading span{
    width: 10%;
}
.product_category_heading span .fa{
    padding: 10px 15px;
    color: #eee;
    font-size: 18px;
    margin: 0px 0px 0px 0;
}
.product_category_heading .fa:hover{
    background-color: #222;
}
.product_category{
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 0px 0;
}
.play_icon {
    background: url(assets/Icons/playbtn.png) 0 0 no-repeat;
    background-position: center;
    width: 80px;
    height: 80px;
    margin:127% 0 0 65%;
    border-radius: 50%;
}

.product_category .slick-prev, .slick-next {
    top: 50%;
    width: 42px;
    margin-top: -45px;
    height: 42px;
}

.product_category .slick-next {
    z-index: 9;
    top: 50%;
    right: -50px;
    background: url(assets/image/next-icon.png) center center no-repeat transparent;
    background-position: center center;
    opacity: 1;
}

.product_category .slick-prev {
    left: 0;
    margin-left: -20px;
    transform: 0;
    z-index: 9;
    top: 50%;
    background: url(assets/image/prev-icon.png) center center no-repeat transparent;
    background-position: center center;
    opacity: 1;
}
.explore_btn{
    padding: 0 30px;
    line-height: 44px;
    border-radius: 4px;
    background-color: #D90917;
    transition: all ease-in-out 0.3s;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 0; 
    color: #fff;
}
.explore_btn span{
    position: relative;
    top: -3px;
}
.explore_btn:hover{
    background-color: #fe7d02;
}

.newsletter {
    width: 100%;
    display: inline-block;
    background-size: cover;
    background: url(assets/image/newsletter_bg.jpg) 0 0 no-repeat;
    margin: 60px 0;
    padding: 100px 0;
    background-position: center;
}
.newsletter h2{
    width: 100%;
    display: inline-block;
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
}
.newsletter h3{
    width: 100%;
    display: inline-block;
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
}
.newsletter_form{
    margin: 0 auto;
    display: table;
    width: 80%;
    position: relative;
}
.newsletter_form input[type=text]{
    width: 100%;
    display: inline-block;
    border: 1px solid #D90917;
    line-height: 80px;
    background-color: rgba(0,0,0,0.50);
    padding: 0 220px 0 20px;
    font-size: 22px;
    color: #fff;
}
.newsletter_form input[type=submit]{
    width: 200px;
    line-height: 80px;
    top: 0;
    right: 0;
    position: absolute;
    background-color: #D90917;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    border: none;
}
.newsletter_form input[type=text]:focus{
    outline: none;
}
.newsletter_form input[type=text]::placeholder{
    color: #fff;
}
.newsletter_form input[type=submit]:focus{
    outline: none;
}
.download_app{
    margin: 50px auto 0;
    display: table;
    position: relative;
}
.download_app h4{
    float: left;
    font-size: 50px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    margin-right: 25px;
}
.download_app a{
    float: left;
    margin: 0 25px;
}
a{
    text-decoration: none;
}
footer {
    width: 100%;
    display: inline-flex;
    margin:0px 0% 0 0%;
    padding: 20px 10% 0 10%;
    background-image: url('./assets/Icons/Group\ 66365.png');
    background-size: cover;
}

.footer_logo_icon {
    margin: 0 auto 4 0px;
    display: table;
}
.footer_download h3{
    width: 100%;
    display: inline-block;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    margin: 20px 0 20px 0;
}
.footer_logo img{
    max-width: 100%;
}
.footer_logo {
    width: 80%;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 0;
}
.footer_download{
    width: 100%;
    display: inline-block;
}
.footer_download a:last-child{
    margin-right: 0;
}
.footer_area h2{
    width: 100%;
    display: inline-block;
    font-size: 36px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    padding: 0;
}
ul.quick_link{
    padding: 0 0px;
    margin: 0;
    list-style-type: none;
    width: 100%;
    display: inline-block;
}
ul.quick_link li {
    width: 100%;
    display: inline-block;
    padding: 0;
    margin: 5px 0px 0px 0;
}
ul.quick_link li a{
    width: 100%;
    display: inline-block;
    font-size: 20px;
    font-weight: normal;
    color: #fff;
    transition: all ease-in-out 0.3s;
}
ul.quick_link li a:hover{
    color: #D90917;
}
ul.quick_link li.active a{
    color: #D90917;
}

ul.social_icon{
    padding: 0 10px;
    margin: 0;
    list-style-type: none;
    width: 100%;
    display: inline-block;
}
ul.social_icon li {
    float: left;
    padding: 0;
    margin: 0px 0px 0 30px; 
}
ul.social_icon li:first-child{
    margin-left: 0;
}
ul.social_icon li:last-child{
    margin-right: 0;
}
ul.social_icon li a {
    width: 100%;
    display: inline-block;
    font-weight: normal;
    color: #fff;
}
ul.social_icon li a .fa{
    font-size: 24px;
}
ul.social_icon li a:hover{
    color: #D90917;
}

.last_footer{
    width: 100%;
    display: inline-block;
    margin-top: 60px;
    border-top: 1px solid #D90917;
}

.last_footer p{
    width: 100%;
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    padding: 20px 0;
}
.last_footer p span{
    font-weight: 600;
    color: #D90917;
}


.right{
    float: right;
}
.left{
    float: left;
}
.content_area{
    width: 100%;
    display: inline-block;
    height: 100%;
}
.content_inner_area{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.content_inner_area ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    display: inline-block;
}
.content_inner_area ul li {
    margin: 12px auto;
    display: table;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    padding-left: 25px;
    position: relative;
}
.content_inner_area ul li:after {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url(assets/image/content_right_icon.png) 0 0 no-repeat;
    content: "";
    top: 8px;
    left: 0;
}
.flip_btn {
    width: 230px;
    font-size: 25px;
    font-weight: normal;
    color: #fff;
    padding: 0;
    line-height: 70px;
    display: table;
    text-align: center;
    border-radius: 5px;
    margin: 0 auto;
    background-color: #D90917;
    z-index: 9;
    text-transform: uppercase;
    position: relative;
    transition: all ease-in-out 0.3s;
}
.flip_btn:hover{
    background-color: #a50d20;
}

.flip_btn_area:after {
    width: 200px;
    left: -30px;
    background: #D90917;
    background: linear-gradient(90deg, rgba(208,8,32,0) 0%, #D90917 68%);
    height: 2px;
    content: "";
    top: 50%;
    position: absolute;
}
.flip_btn_area:before {
    width: 200px;
    right: -30px;
    background: #D90917;
    background: linear-gradient(-90deg, rgba(208,8,32,0) 0%, #D90917 68%);
    height: 2px;
    content: "";
    top: 50%;
    position: absolute;
}

.flip_btn_area{
    width: 70%;
    margin: 0 auto;
    display: table;
    position: relative;
}
.content_inner_area h3 {
    width: 100%;
    display: inline-block;
    font-size: 25px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 40px;
}
.login{
    height: auto;
    width: 100%;
    padding: 0px;
    background-image: url('./assets/Icons/colorful-abstract-nebula-space-background\ 1.png');
    background-size: cover;
    margin: 0% 0% 0% 0%;
}

.login .logg{
    width: 36%;
    border-radius: 12px;
    margin: 1% 32%;
    padding: 20px 10px;
    display: inline-block;
    height: auto;
    border: 2px solid #D90917;
}
.login .logg .recap{
    margin: 10px 30px;
}
.login .logg .dat{
    width: 100%;
    display: inline-flex;
    padding: 10px;
}
.login .logg .small{
    margin: 0 0 5px 25px;
}
.login .logg .dat .dat1{
    width: 49%;
    margin:  0 0 0 1%;
    display: inline-flex;
}  
.login .logg .dat .dat1 .label{
    padding: 20px 10px 20px 10px;
    color: #fff;
}
.login .logg .dat .dat1 input[type="radio"]{
    width: 20px;
    margin:  0 0 0 20px;
    height:auto;
}
.login .logg h1{
    text-align: center;
    color: #fff;
}
.login .logg p{
    text-align: center;
    color: #ccc;
}
.login .logg input{
    padding: 12px 15px;
    border-radius: 6px;
    border: 1px solid #ccc;
    margin: 0px 0 0 0;
    color: #fff;
    background-color: #111;
    width:90%;
    margin:10px 5%;
}
.login .logg input::placeholder{
    color: #fff;
}
.login .logg input[type="radio"]{
    color: #fff;
    padding: 0;
    margin:0;
}
.login .logg .input_field_col .form-check {
    padding: 10px;
}
.login .logg .otp{
    display: inline-flex;
    width: 76%;
    margin: 10px 12%;
}
.login .logg .otp input[type="text"]{
    width: 40px;
    height: 50px;
    font-size: 26px;
    padding: 5px 12px;
}
.login .logg .flexbox{
    display: inline-flex;
    width: 90%;
    margin: 10px 5%;
}
.login .logg .flexbox input{
    width: 5%;
    height: 20px;
    padding: 6px;
    margin: 0;
}
.login .logg .flexbox input:focus-visible{
    outline: 2px solid #D90917;
}
.login .logg .flexbox p{
    padding: 0;
    width: 95%;
} 
.login .logg .flexbox p a{
    color: #D90917;
    border-bottom:2px solid #D90917;
}
.login .logg .social-icons{
    width: 30%;
    display: inline-flex;
    margin: 10px 35%;
}
.login .logg .social-icons .fa{
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #0BDADA;
    color: #0BDADA;
    font-size: 18px;
    margin: 0 10px;
}
.login .logg .social-icons .fa-facebook{
    padding: 10px 12px; 
}


.login .logg .playbtn{
    width: 40%;
    margin: 20px 30% 10px 30%;
    color :#fff;
    background-color: #D90917;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    padding: 13px 0px;
}
a.back_home {
    position: absolute;
    top: 20px;
    left: 20px;
}
a.back_home span {
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    position: relative;
    padding-left: 35px;
}
a.back_home span:after {
    position: absolute;
    content: "";
    background: url(assets/image/home_back_icon.png) 0 0 no-repeat;
    width: 28px;
    height: 15px;
    top: 6px;
    left: 0;
}
.content_heading{
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: -70px;
}
.content_heading h2{
    font-size: 50px;
    font-weight: 500;
    color: #fff;
    position: relative;
    padding-bottom: 20px;
    margin: 0 auto;
    display: table;
    text-align: center;
}
.content_heading h2 span{
    color: #D90917;
}
.content_heading h2:after{
    position: absolute;
    content: "";
    width: 100px;
    height: 1px;
    background-color: #D90917;
    margin-left: -50px;
    left: 50%;
    bottom: 0;
}
.input_field_col{
    position: relative;
    width: 100%;
    display: inline-block;
}
.input_field_col label{
    color: #fff;
}
.input_field_col input[type="text"], .input_field_col input[type="email"]#login_email {
    width: 100%;
    display: inline-block;
    padding: 0 20px 0px 50px;
    border: 1px solid #fff;
    color:#fff;
    background-color: #121212;
    border-radius: 3px;
    line-height: 45px;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 30px;
}
.input_field_col input[type="password"] {
    width: 100%;
    display: inline-block;
    padding: 0 20px 5px 50px;
    border: 1px solid #fff;
    color: #fff;
    background-color: #121212;
    border-radius: 3px;
    line-height: 60px;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 30px;
}
.input_field_col span {
    position: absolute;
    top: 17px;
    left: 20px;
}
.input_field_col input[type="password"]:focus, .input_field_col input[type="text"]:focus, .input_field_col input[type="submit"]:focus{
    outline: none;
}
.input_field_area{
    width: 100%;
    display: inline-block;
    margin-top: 50px;
}

.forgot{
    float: left;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    line-height: 60px;
    transition: all ease-in-out 0.3s;
}
.forgot:hover{
    color: #ccc;
}
.input_field_col input[type="submit"] {
    padding: 0 38px;
    border: none;
    color: #fff;
    background-color: #D90917;
    border-radius: 3px;
    line-height: 60px;
    font-size: 18px;
    float: right;
    font-weight: 500;
    text-align: center;
    transition: all ease-in-out 0.3s;
}
.input_field_col .login{
    padding: 0 38px;
    border: none;
    color: #fff;
    background-color: #D90917;
    border-radius: 3px;
    line-height: 60px;
    font-size: 18px;
    float: right;
    font-weight: 500;
    text-align: center;
    transition: all ease-in-out 0.3s;
    margin-top: 50px;
}

.input_field_col input[type="submit"]:hover{
    background-color: #a50d20;
}
.or_devider{
    width: 100%;
    display: inline-block;
    margin: 40px 0 30px 0;
    position: relative;
}
.or_devider:after {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #D90917;
    top: 13px;
    left: 0;
    content: "";
}
.or_devider span {
    padding: 0 15px;
    line-height: 23px;
    font-size: 18px;
    color: #D90917;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    background-color: #121212;
    display: table;
    position: relative;
    z-index: 9;
}

ul.social_connect{
    padding: 0;
    
    margin-top: -20px;
    list-style-type: none;
}
ul.social_connect li{
    float: left;
    margin-right: 20px;
    border:1px solid #D90917;
    padding: 8px 30px 8px 15px; 
}

ul.social_connect li:last-child{
    margin-right: 0;
}
ul.social_connect li a{
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: 600; 
    line-height: 40px;  
    color: #fff;
}
ul.social_connect li a span {
    float: left;
    margin-right: 12px;
    position: relative;
    top: -3px;
}
.input_field_col p {
    position: relative;
    font-size: 16px;
    color: #fff;
    text-align: left;
    width: 100%;
    display: inline-block;
    top: -18px;
    margin-bottom: 10px;
}
.new {
    float: left;
    margin-top: 9px;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
}
.form-group label span {
    color: #D90917;
    width: unset;
    left: unset;
    top: unset;
    float: none;
    position: relative;
}
.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: #585858;
    border: 1px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 2px;
    margin-right: 10px;
    top: -2px;
}

.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 4px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


.form-group input:checked + label:before {
    background-color: #D90917;
}
.inner_banner .banner_image img {
    height: 85vh;
}
/*content details css*/
.content-details{
    padding:10px;
    margin: 0px 0;
    width: 100%;
}
.content-details .container .select{
    width:15%;
    color:#fff;
    margin: 15px 10px 15px 10px;
}
.content-details .container .col-md-9 .reactsmallplayer, .content-details .container .col-md-12 .reactsmallplayer{
    width: 100%;
    height: auto;
    padding: 0 10px;
    margin: 0;
}
.content-details .container .reactsmallplayer .playerWrapper{
    width: 100%;
    margin: 0;
    padding: 0;
}
.content-details .container .col-md-9 p.heading,.content-details .container .col-md-12 p.heading{
    border-radius: 6px;
    padding: 5px 10px 5px 10px;
    width: 150px;
    color: #fff;
    background-color: #D8000F;
    font-size: 16px;
    margin: 10px 10px;
}

.content-details .container .col-md-9 .tpbutton,.content-details .container .col-md-12 .tpbutton{
    position: absolute;
    z-index: 99999;
    cursor: pointer;
    width: 60%;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    height: auto;
    top: 10%;
    border-radius: 6px;
    left:20%;

}
.content-details .container .col-md-12 img{
    width: 100%;
    margin: 0px 0%;
    border-radius: 4px;
    padding: 0;
}
.content-details .container .col-md-12  p{
    padding: 10px 0 0 10px;
    font-size: 20px;
    color:#ccc;
}.content-details .container .col-md-12  p span{
    color:#fff;
}
.content-details .container .col-md-12  h1{
    padding: 0px 0 0 10px;
    color:#fff;
    font-size: 44px;
}
.content-details .container .col-md-12  ul{
    display: inline-flex;
    width: 100%;
    margin: 15px 0;
    overflow-x: scroll;
}.content-details .container .col-md-12  ul::-webkit-scrollbar{
    display: none;
}
.content-details .container .col-md-12  ul li{
    list-style-type: none;
    border: 2px solid #ccc;
    color: #fff;
    font-size: 20px;
    padding:6px 20px;
    margin: 10px 0px 0 10px;
}
.content-details .container .col-md-12  .buttons{
    display: flex;
    padding: 20px 10px;
}
.content-details .container .col-md-12  .buttons .fa{
    font-size: 16px;
    padding: 8px 10px;
    color: #D8000F;
    margin: 0 10px 0 0;
    border-radius: 50%;
    opacity: 1;
    background-color: #fff;
}
.content-details .container .col-md-12  .buttons .playbtn{
    background-color: #D8000F;
    display: inline-flex;
    border-radius: 6px;
    padding: 8px 10px 10px 40px;
    height: 50px;
    width: 320px;
    font-size: 18px;
    font-weight: 500;
    margin:0 10px 0 0;
}
.content-details .container .col-md-12  .buttons span{
    padding: 5px 0 0 0;
}

/*if season not there*/
.content-details .container .col-md-9 img{
    width: 100%;
    margin: 0px 0%;
    padding: 0;
}
.content-details .container .col-md-9  p{
    padding: 10px 0 0 10px;
    font-size: 20px;
    color:#ccc;
}.content-details .container .col-md-9  p span{
    color:#fff;
}
.content-details .container .col-md-9  h1{
    padding: 0px 0 0 10px;
    color:#fff;
    font-size: 44px;
}
.content-details .container .col-md-9  ul{
    display: inline-flex;
    width: 100%;
    overflow-x: scroll;
    margin: 15px 0;
}.content-details .container .col-md-9  ul::-webkit-scrollbar{
    display: none;
}
.content-details .container .col-md-9  ul li{
    list-style-type: none;
    border: 2px solid #ccc;
    color: #fff;
    padding:6px 20px;
    font-size: 20px;
    margin: 10px 0px 0 10px;
}
.content-details .container .col-md-9  .buttons{
    display: flex;
    padding: 20px 10px;
}
.content-details .container .col-md-9  .buttons .fa{
    font-size: 14px;
    padding: 8px 10px;
    color: #D8000F;
    margin: 0 10px 0 0;
    border-radius: 50%;
    opacity: 1;
    background-color: #fff;
}
.content-details .container .col-md-9  .buttons .playbtn{
    background-color: #D8000F;
    display: inline-flex;
    border-radius: 6px;
    padding: 8px 10px 10px 40px;
    height: 50px;
    width: 320px;
    font-size: 18px;
    font-weight: 500;
    margin:0 10px 0 0;
}
.content-details .container .col-md-9  .buttons span{
    padding: 5px 0 0 0;
}

/*if there is not subscription*/

.content-details .trail{
    display: none;
}
.content-details .container .col-md-12 .season{
    width: 100%;
    display: inline-flex;
}

.content-details .container .col-md-12 .season .episode{
    width: 24%;
    margin: 0 0% 0 1%;
}
.content-details .container .col-md-12 .season .episode img{
    width: 100%;
    margin: 0px 0 5px 0;
    border-radius: 4px;
}
.content-details .container .col-md-12 .season .episode img:hover{
    border: 2px solid #ccc;
}
.content-details .container .col-md-12 .season .episode p{
   color: #fff;
   padding: 0px 0 15px 0;
   margin: 0;
}

.content-details .container .col-md-3.new_subs{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 580px;
    width: 360px;
    padding: 10px;
    margin-top: 10px;
}
.content-details .container .col-md-3.new_subs h2{
    font-size: 22px;
    color: #fff;
    padding: 10px 0 0 10px;
}
.content-details .container .col-md-3.new_subs p{
    font-size: 14px;
    color: #ccc;
    padding: 0px 10px 30px 10px;
}
.content-details .container .col-md-3.new_subs .package{
    width: 95%;
    padding: 15px 15px 20px 15px;
    margin: 0px 2.5% 10px 2.5%;
    /*background-image: linear-gradient(to top,#d90917,#000);*/
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.3);
}



.content-details .container .col-md-3.new_subs .package:hover {
    border: 1px solid #fff;
}
.content-details .container .col-md-3.new_subs .package:hover button.btn_buy{
    border: 1px solid #fff;
}
.content-details .container .col-md-3.new_subs .package p{
    color: #fff;
    width: 100%;
    padding: 0 8px;
}
.content-details .container .col-md-3.new_subs .package p input[type=radio]{
    width: 18px;
    accent-color: red;
    float: right;
    border: none;
    height: 18px;
    margin: 10px 0px 0 0;
}
.content-details .container .col-md-3.new_subs .package p:nth-child(1){
    font-size: 24px;
    /*background: linear-gradient(
        to right, #d90917, #fff,
         #cf23cf, #eedd44);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;*/
    color: #fff;
}
.content-details .container .col-md-3.new_subs .package p:nth-child(2){
    font-size: 16px;
    margin-top: -5px;
}
.content-details .container .col-md-3.new_subs .package p img{
    margin: -5px 0px 0 0px;
}
.content-details .container .col-md-3.new_subs button.btn_buy{
    width:95%;
    margin: 15px 2.5% 15px 2.5%;
    color: #fff;
    font-size: 18px;
    background-color:#D90917;
    animation:none;
}


/*about*/
.inner_about {
    width: 100%;
    display: inline-block;
    margin: 20px 0 0 0;
}
.inner_about .container .row .col-md-6:nth-child(2) img{
    width: 50%;
    margin: 5% 45% 0 5%;
}
.inner_about .container .row .col-md-6:nth-child(1){
    padding: 5% 0 2% 15%;
}
.inner_about .container .row .col-md-6 h1{
    color: #fff;
    padding: 10px 0;
    font-size: 45px;
}
.inner_about .container .row .col-md-6 p{
    color: #ccc;
    font-size: 18px;
    padding: 10px 0;
}
/*subscribe*/
.inner_subcribe{
    width: 100%;
    display: inline-block;
    padding: 80px 0;
}
.inner_subcribe_area {
    max-width: 1300px;
    margin: 0 auto;
    display: table;
}
.subscribe_heading{
    width: 100%;
    display: inline-block;
    position: relative;
}

.subscribe_heading h2{
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 25px;
}

.subscribe_heading h2:after {
    position: absolute;
    content: "";
    width: 150px;
    height: 1px;
    bottom: 0;
    left: 50%;
    margin-left: -74.5px;
    background-color: #D90917;
}
ul.subscribe_info{
    margin: 50px auto 0;
    display: table;
    padding: 0;
    list-style-type: none;
}
ul.subscribe_info li{
    margin: 20px auto 0;
    padding: 0;
    display: table;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    position: relative;
    padding-left: 28px;
}

ul.subscribe_info li:after{
    position: absolute;
    content: "";
    background: url(assets/image/subscribe_info_icon.png) 0 0 no-repeat;
    width: 20px;
    height: 20px;
    top: 8px;
    left: 0;
}
.membership{
    width: 100%;
    display: inline-block;
    margin-top: 80px
}

.membership h2{
    width: 100%;
    display: inline-block;
    font-size: 45px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    margin-bottom: 60px;
}
.membership h2 span{
    color: #D90917;
}
.membership ul{
    margin: 0 auto;
    display: table;
    padding: 0;
    list-style-type: none;
}
.membership ul li{
    float: left;
    width: 400px;
    margin-right: 50px;

}
.membership ul li:last-child{
    margin-right: 0;
}
.membership_col {
    width: 100%;
    position: relative;
    background-color: #1B1B1B;
    box-shadow: 0 25px 45px rgba(0,0,0,0.25);
    display: inline-block;
    padding: 40px 20px;
}
.policy{
    width: 100%;
    display: inline-block;
    padding: 80px 0;
}
.policy_area {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    display: table;
}
.policy_area_col{
    width: 100%;
    display: inline-block;
    margin-bottom: 50px;
}
.policy_heading{
    width: 100%;
    display: inline-block;
    position: relative;
}

.policy_heading h2,h5{
    margin: 0 auto;
    font-size: 40px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
    line-height: 50px;
    position: relative;
    width: 100%;
    padding: 20px 30px 0 25px;
}

.policy_heading h2 span{
    color: #D90917;
}
/*sundaram*/
.policy_area_col p{
    width: 95%;
    display: inline-block;
    text-align: left;
    font-size: 20px;
    font-weight: 300;
    border-left: 2px solid #D8000F;
    color: #fff;
    margin:-20px 2.5% 0 2.5%;
    padding: 5px 20px;
}
.policy_area_col h5{
color:#fff;
padding: 10px 0px 0px 30px;
}
.policy_area h3{
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    color: #D90917;
}

@media screen and (max-width:1518px){
    .right_nav{
        width: 60%;
        float: right;
        padding: 0px 0% 5px 0px;
        margin: 0;
        height: 59px;
        border-bottom: 3px solid #333;
        background-color: rgba(0, 0, 0, 0.3);
    }
}
@media screen and (max-width:1208px){
    .right_nav{
        width: 80%;
        float: right;
        padding: 0px 0% 5px 0px;
        margin: 0;
        height: 59px;
        border-bottom: 3px solid #333;
        background-color: rgba(0, 0, 0, 0.3);
    }
}
/*ritam*/
@media (max-width: 1199px) {
    header .right_nav .mobile_primary ul{
        display: none;
        width: 100%;
    }
   
    
.banner-content h1 {
    font-size: 58px;
    line-height: 62px;
    margin-bottom: 30px;
}
.sec_heading h2 {
    font-size: 50px;
    padding-bottom: 40px;
}
.sec_heading h2:after {
    width: 400px;
    height: 28px;
    margin-left: -200px;
    background-size: 400px;
}

.product_category_heading h4 {
    font-size: 24px;
}
.product_item h3 {
    font-size: 16px;
}
.download_app h4 {
    font-size: 36px;
    margin-top: 6px;
}
.newsletter h3 {
    font-size: 50px;
    margin-bottom: 30px;
}
.newsletter h2 {
    font-size: 30px;
    margin-bottom: 30px;
}
.newsletter_form input[type=text] {
    line-height: 60px;
    padding: 0 180px 0 20px;
    font-size: 18px;
}
.newsletter_form input[type=submit] {
    width: 160px;
    line-height: 60px;
    font-size: 18px;
}
.footer_download h3 {
    font-size: 30px;
}
.footer_area h2 {
    font-size: 24px;
    margin-bottom: 30px;
}
.footer_download a{
    width: 50%;
    float: left;
    padding: 0 10px; 
    margin: 0;
}
.footer_download a img{
    max-width: 100%;
}
ul.social_icon li {
    margin: 0 20px;
}
ul.social_icon li a i{
    font-size: 30px;
}
.footer_logo_icon{
    width: 60%;
}
.footer_logo_icon img{
    max-width: 100%;
}
.login_inner_area {
    width: 80%;
}
.content_heading h2 {
    font-size: 30px;
}
.input_field_area {
    margin-top: 30px;
}
.content_heading h2:after {
    width: 50px;
    margin-left: -25px;
}
.input_field_col input[type="text"] {
    line-height: 40px;
    font-size: 15px;
    padding: 0 15px 0 40px;
}

.input_field_col input[type="password"] {
    line-height: 40px;
    font-size: 15px;
    padding: 0 15px 0 40px;
}
.input_field_col span {
    top: 6px;
    width: 15px;
    left: 15px;
}
.input_field_col span img{
    width: 100%;
}
.input_field_col input[type="submit"] {
    line-height: 40px;
    font-size: 16px;
    padding: 0 22px;
}
.or_devider span {
    padding: 0 10px;
    line-height: 20px;
    font-size: 16px;
}
.or_devider:after {
    top: 10px;
}
ul.social_connect li a {
    font-size: 14px;
    line-height: 28px;
}
ul.social_connect li a span {
    margin-right: 6px;
}
.inner_banner .banner_overlay {
    padding: 150px 0 0 0;
    
}
.banner_subsc_btn span{
    width: 25px;
}
.banner_subsc_btn span img{
    width: 100%;
}
.membership ul {
    width: 100%;
}
.membership ul li {
    width: 30%;
    margin-right: 3%;
}

.inner_subcribe_area {
    width: 100%;
}
.membership_col h4:after {
    width: 40px;
    left: -50px;
}
.membership_col h4:before {
    width: 40px;
    right: -50px;
}
.membership_col p {
    font-size: 20px;
}
.membership_col h5 {
    font-size: 50px;
}
.membership_col h4 {
    font-size: 50px;
}
.membership_col h5 span {
    font-size: 20px;
}
.membership h2 {
    font-size: 36px;
}
.subscribe_heading h2 {
    font-size: 40px;
    padding-bottom: 20px;
}
ul.subscribe_info li {
    font-size: 20px;
}

/*ritam*/
.main {
    width: 90%;
}

/*ritam*/
}

@media screen and (max-width: 1024px) {
    .content_popup{
        background-color: #1B1B1B;
        width: 70%;
        height: auto;
        align-content: center;
        justify-content: center;
        margin: 6% 15%;
        color: #fff;
        padding: 20px;
        border-radius: 12px;
        position: absolute;
        z-index: 999999999;
    }
   .click_to_subsc{
    display: none;
   }
   .click_to_category{
    display: none;
   }
.toggle_search{
    display: none;
}
    .click_to_category{
        display: none;
    }
.banner-content h3 {
    font-size: 60px;
    line-height: 60px;
}
.banner-content h3:after {
    height: 42px;
}
.banner-content h4 {
    font-size: 20px;
    letter-spacing: 20px;
}
.left {
    float: none;
}
.right {
    float: none;
}
.content_area {
    border: none; 
}
.login_logo {
    width: 120px;
    margin: 0;
    display: inline-block;
}
.content_inner_area ul {
    margin-top: 30px;
}
.content_inner_area ul li {
    font-size: 16px;
    margin: 10px 0;
    display: inline-block;
    padding-left: 25px;
    font-weight: 500;
    width: 100%;
}
.content_inner_area ul li:after {
    top: 3px;
    width: 16px;
    background-size: 16px;
    height: 16px;
}
.logincontent {
    width: 100%;
}
.content_inner_area h3 {
    font-size: 18px;
    text-align: left;
}
.flip_btn_area {
    width: 100%;
    margin: 0;
    display: inline-block;
}
.flip_btn {
    width: unset;
    font-size: 15px;
    line-height: 44px;
    z-index: 9;
    display: inline-block;
    padding: 0 20px;
}
.flip_btn_area:before{
    display: none;
}
.flip_btn_area:after{
    display: none;
}
.login_form {
    height: 100vh;
    width: 380px;
    position: absolute;
    top: 0;
    right: 0;
}
.login_form_area {
    border: none;
}
.login_inner_area {
    width: 400px;
}
.login_inner_area {
    width: 340px;
}
a.back_home {
    top: 0;
    left: 0;
}
.input_field_col p {
    font-size: 14px;
    top: -19px;
}
a.back_home span {
    font-size: 0;
}
.or_devider {
    margin: 30px 0 15px 0;
}
.footer_download a{
    width: unset;
}

.product_category .slick-slide img {
    height: unset;
}
.banner-content h1 {
    font-size: 42px;
    line-height: 52px;
}
.banner-content {
    width: 100%;
}
.banner_btn_area:before {
    width: 35%;
    right: 0;
}
.banner_btn_area:after {
    width: 35%;
    left: 0;
}
.click_to_subsc {
    font-size: 16px;
}
.toggle_search {
    font-size: 14px;
}

.sec_heading h2 {
    font-size: 40px;
    padding-bottom: 35px;
}

.download_app h4 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 30px;
    width: 100%;
    margin-right: 0;
    text-align: center;
}
.download_app a {
    float: none;
    margin: 15px auto 0;
    display: table;
}
.footer_area h2 {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 50px;
}
.membership_col p {
    font-size: 18px;
}
.membership_col h5 {
    font-size: 40px;
}
.membership_col h5 span {
    font-size: 18px;
}
.membership_col h4 {
    font-size: 35px;
}
.membership_col h3 {
    font-size: 18px;
}
.membership_col h3:after {
    width: 8px;
    height: 15px;
    top: 6px;
    background-size: 8px;
}
.membership_col h3 {
    font-size: 18px;
    padding-left: 14px;
}


/*ritam*/
.item_info h2 {
    font-size: 40px;
}
.item_info ul li a {
    font-size: 16px;
    line-height: 30px;
    padding: 0 15px;
}
.item_info ul li {
    margin-right: 15px;
}
.item_info_col h3 {
    font-size: 30px;
    line-height: 30px;
}
.item_info_col p {
    font-size: 17px;
}
.details {
    margin-top: 180px;
}
/*ritam*/
}
@media (max-width: 767px) {
    .episode_playback,.trailer_playback{
        width:100%;
        margin:0px 0%;
    }
    .category_small_screen{
        display: block;
    }

.newsletter h3 {
    font-size: 40px;
}
.newsletter h2 {
    font-size: 24px;
    margin-bottom: 25px;
}
.product_category_heading h4 {
    font-size: 20px;
}
.click_to_subsc, .click_to_category{
    display: none;
}
.banner_overlay {
    display: none;
    padding: 150px 0 0 0;
}

.toggle_search {
    font-size: 0;
}
.login_form {
    height: unset;
    width: 380px;
    position: relative;
}

.logincontent{
    height: unset;
}
.content_heading h2 {
    font-size: 24px;
}
.input_field_col input[type="password"] {
    line-height: 36px;
}
.input_field_col span {
    top: 5px;
    width: 12px;
}
.login_form {
    width: 100%;
}
.flip_btn {
    display: table;
}
.content_inner_area h3 {
    font-size: 18px;
    text-align: center;
}
.content_inner_area ul li {
    font-size: 16px;
    margin: 10px auto;
    display: table;
    padding-left: 25px;
    font-weight: 500;
    width: 100%;
    text-align: left;
}
.content_inner_area ul {
    margin: 30px auto 0;
    display: table;
    width: unset;
}
.login_logo {
    margin: 0 auto;
    display: table;
}

ul.subscribe_info li {
    font-size: 16px;
    margin: 24px auto 0;
}
ul.subscribe_info li:after {
    top: 1px;
}
.membership h2 {
    font-size: 30px;
}
.subscribe_heading h2 {
    font-size: 30px;
}
.membership ul li {
    width: 50%;
    padding: 8px;
    margin-right: 0;
}
.membership ul li:last-child {
    margin: 0 auto 0;
    display: table;
    float: none;
}
}




/* Arjun */

body {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}
.password-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 100px 0;
}
.inner-logo {
    margin: 0 auto 90px;
}

.inner-logo img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}

.password-wrapper {
    max-width: 1000px;
    background: #FFFFFF;
    padding: 50px 200px;
    margin: 0 auto;
}

.password-wrapper .content_heading h2 {
    font-size: 50px;
    font-weight: 500;
    color: #121212;
    position: relative;
    padding-bottom: 25px;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-family: 'Futura PT';
    display: block;
}

.password-wrapper .content_heading h2::after {
    width: 150px;
    height: 2px;
    background-color: #D90917;
    margin-left: -75px;
}
.password-wrapper .input_field_area {
    width: 100%;
    display: block;
    margin-top: 50px;
}

.password-wrapper .input_field_col {
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    display: block;
}

.password-wrapper .input_field_col input[type="password"],
.password-wrapper .input_field_col input[type="text"],
.password-wrapper .input_field_col input[type="email"] {
    width: 100%;
    height: 60px;
    display: block;
    padding: 0 20px 0 50px;
    border: 1px solid #121212;
    color: #121212;
    background-color: #FFFFFF;
    border-radius: 3px;
    line-height: 1.3em;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0;
    font-family: 'Futura PT';
}

.password-wrapper .input_field_col span {
    top: 14px;
}

.password-wrapper .input_field_col input[type="submit"] {
    padding: 0 0;
    border: none;
    color: #fff;
    background-color: #D90917;
    border-radius: 5px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    transition: all ease-in-out 0.3s;
    width: 180px;
    height: 60px;
    text-transform: uppercase;
    font-family: 'Futura PT';
    float: none;
    margin: 50px auto 0;
    display: block;
}

.password-wrapper .input_field_col input[type="submit"]:hover {
    background-color: #D90917;
}
.submit-col {
    position: relative;
}
.submit-col::before {
    width: 30%;
    right: -20px;
    background: #D90917;
    background: linear-gradient(-90deg, rgba(208,8,32,0) 0%, #D90917 68%);
    height: 3px;
    content: "";
    top: 50%;
    position: absolute;
}

.submit-col::after {
    width: 30%;
    left: 0px;
    background: #D90917;
    background: linear-gradient(90deg, rgba(208,8,32,0) 0%, #D90917 68%);
    height: 3px;
    content: "";
    top: 50%;
    position: absolute;
}

.digit-group {
    text-align: center;
}

.password-wrapper .digit-group input {
    width: 18%;
    height: 60px;
    background-color: transparent;
    border: none;
    border-bottom: 5px solid #121212;
    line-height: 1.5em;
    text-align: center;
    font-size: 36px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: #121212;
    margin: 0 3%;
  }
  .digit-group .splitter {
    padding: 0 5px;
    color: #121212;
    font-size: 24px;
  }
  
  .prompt {
    margin-bottom: 20px;
    font-size: 20px;
    color: #121212;
  }

.digit-group input::-webkit-input-placeholder {
    color: #121212 !important;
  }
.digit-group input::-moz-placeholder { 
    color: #121212 !important;
  }
.digit-group input:-ms-input-placeholder {
    color: #121212 !important;
  }
.digit-group input:-moz-placeholder {
    color: #121212 !important;
  }
@media (max-width: 1024px) {
    header .right_nav .mobile_primary{
        display: block;
        float: right;
     }
    .password-section {
        background-size: cover;
        padding: 60px 50px;
    }

    .password-wrapper {
        padding: 50px 100px;
    }
}

@media (max-width: 780px) {
    .content_popup{
        background-color: #111;
        width: 400;
        height: auto;
        align-content: center;
        justify-content: center;
        margin: 15% 30%;
        color: #fff;
        padding: 20px;
        border-radius: 12px;
        position: absolute;
        z-index: 999999999;
    }
    .content_popup h2{
        color:#D90917;
        font-size: 42px;
        text-align: center;
        margin: 20px 0px;
    }
    .content_popup p{
    color: #ccc;
    text-align: center;
    width: 70%;
    font-size: 18px;
    margin: 30px 15% 10px 15%;
    }
    .content_popup p.head{
        color: #fff;
        font-size: 22px;
        width: 90%;
        margin: 0px 5%;
    }
    .content_popup .buttons{
        display: inline-flex;
        width: auto;
        margin: 20px 25%;
        width:50%;
    }   
}
@media (max-width: 767px) {
    .password-section {
        background-size: cover;
        padding: 30px 20px;
    }

    .password-wrapper {
        padding: 20px 20px;
    }
    .content_heading{
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top:0px;
    }
    .password-wrapper .content_heading h2 {
        font-size: 22px;
        padding-bottom: 10px;
    }

    .submit-col::before {
        width: 18%;
    }
    .submit-col::after {
        width: 18%;
        left: -20px;
    }
    .inner-logo {
        margin: 0 auto 35px;
        max-width: 100px;
    }

    .password-wrapper .digit-group input {
        margin: 0 2%;
    }

    .password-wrapper .input_field_col input[type="submit"] {
        font-size: 17px;
    }
}

/* contact-us */

.contact-us {
    padding: 90px 0 20px;
}

.contact-wrapper {
    margin:0 auto;
    max-width: 800px;
}
.contact-wrapper h2 {
    width: 100%;
    display: block;
    position: relative;
    padding-left: 0;
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 20px;
    color: #FFFFFF;
    text-align: center;
    line-height: 1.3em;
}

.contact-wrapper h2::after {
    width: 150px;
    left: 50%;
    background: #D90917;
    height: 1px;
    content: "";
    top: 100%;
    position: absolute;
    margin-left: -75px;
}

.contact-form {
    margin-top: 70px;
}

.contact-form  .input_field_col {
    display: block;
    margin-bottom: 40px;
}

.contact-form .input_field_col span {
    top: 28px;
}

.contact-form  .input_field_col input[type="password"],
.contact-form .input_field_col input[type="text"],
.contact-form  .input_field_col input[type="email"] {
    width: 100%;
    height: 80px;
    display: block;
    padding: 0 20px 0 50px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    background-color: #121212;
    border-radius: 3px;
    line-height: 1.3em;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 0;
    font-family: 'Futura PT';
}

.contact-form input::-webkit-input-placeholder {
    color: #FFFFFF !important;
  }
.contact-form input::-moz-placeholder { 
    color: #FFFFFF !important;
  }
.contact-form input:-ms-input-placeholder {
    color: #FFFFFF !important;
  }
.contact-form input:-moz-placeholder {
    color: #FFFFFF !important;
  }

.contact-form  .input_field_col textarea {
    width: 100%;
    height: 250px;
    display: block;
    padding: 20px 20px 20px 25px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    background-color: #121212;
    border-radius: 3px;
    line-height: 1.3em;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 0;
    font-family: 'Futura PT';
    resize: none;
}
.contact-form textarea::-webkit-input-placeholder {
    color: #FFFFFF !important;
  }
.contact-form textarea::-moz-placeholder { 
    color: #FFFFFF !important;
  }
.contact-form textarea:-ms-input-placeholder {
    color: #FFFFFF !important;
  }
.contact-form textarea:-moz-placeholder {
    color: #FFFFFF !important;
  }

.contact-form .input_field_col input[type="submit"] {
    padding: 0 0;
    border: none;
    color: #fff;
    background-color: #D90917;
    border-radius: 5px;
    line-height: 60px;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    transition: all ease-in-out 0.3s;
    width: 220px;
    height: 80px;
    text-transform: uppercase;
    font-family: 'Futura PT';
    margin: 15px auto 0;
    display: block;
}

.contact-form .input_field_col input[type="submit"]:hover {
    background-color: #D90917;
}
@media screen and (max-width:834px){
    .content-details{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .content-details .container .col-md-3.new_subs{
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        height: auto;
        width: 80%;
        margin: 5% 10%;
        padding: 10px;
    }
    .content-details .container .col-md-9 .tpbutton,.content-details .container .col-md-12 .tpbutton{
        position: absolute;
        z-index: 99999;
        cursor: pointer;
        width: 90%;
        height:auto;
        top: 8%;
        left: 0;
        margin: 0 5%;
    }
}
@media (max-width: 767px) {
    .contact-wrapper h2 {
        font-size: 23px;
    }
    .contact-form {
        margin-top: 30px;
    }
    .contact-form .input_field_col input[type="password"], .contact-form .input_field_col input[type="text"], .contact-form .input_field_col input[type="email"] {
        height: 50px;
        padding: 0 20px 0 40px;
        font-size: 15px;
    }
    .contact-form .input_field_col span {
        top: 13px;
    }

    .input_field_col .isd {
        position: absolute;
        top: 6px;
        left: 23px;
        width: auto;
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
    }
    .contact-form .input_field_col {
        display: block;
        margin-bottom: 25px;
    }

    .contact-form .input_field_col textarea {
        height: 150px;
        padding: 20px 20px 20px 25px;
        font-size: 15px;
    }

    .capture img {
        max-width: 100%;
    }

    .contact-form .new {
        float: none;
    }

    .contact-form .input_field_col input[type="submit"] {

        font-size: 19px;
        height: 60px;
        margin: 30px auto 0;
        display: block;
        float: none;
    }

    .toll-free a {
        font-size: 18px;
    }

    .toll-free a i {
        padding-right: 8px;
        font-size: 23px;
    }

    .toll-free {
        margin-top: 30px;
        text-align: center;
    }
}
/*added css*/
.homelogo{
    text-align: center;
}

.getuser{
    padding: 0px;
    height: auto;
    width: 100%;
    margin: 0px 0%;
}
.getuser .container{
    align-content: center;
    justify-content: center;
}
.upgrade{
    border:1px solid #D90917;
    padding: 10px 20px;
    color: #fff;
    float: center;
    border-radius: 12px;
    margin: 0px 10px;
}
.getuser .container img#user{
    width: 8%;
    height: 100px;
    margin: 10px 46%;
    padding: 10px;
    border:1px solid #D90917;
    border-radius: 50%;
    position: relative;
} 
.getuser .images{
    display: inline-flex;
    width: 50%;
    margin: 10px 25%;
    
}
.getuser .container button{
background-color: #D90917;
border-radius: 32px;
width: 20%;

margin: 50px 40%;
}
.getuser hr{
    height: 5px;
    background-color: #D90917;
    width: 20%;
    margin: 10px 40%; 
}
.getuser  p{
    font-size: 22px;
}
.getuser  h3{
text-align: center;
color:#fff;
padding: 20px 0px 10px 0px;
}
.getuser .images a {
    width: 50%;
}
.getuser .images a img{
width: 96%;
margin: 1% 0% 0px 4%;
}
.edit{
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    float: right;
    margin-left: -60px;
    position: absolute;
    background-color: #000;
}


.tag{
    font-size: 32px;
    color:#fff;
    text-align: center;
    width: 100%;
    margin-top: 0px;
    
}
hr{
    color: #D90917;
    opacity: .8;
}

.msg{
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin: 50px 0px;
}
.detail p{
    color: #fff;
    padding: 10px 0px;
    font-size: 19px;
}
.detail{
display: block;
padding: 5px 0px;
}
.subs{
    padding: 10px 5%;
}
.subs .row{
    align-content: center;
    justify-content: center;
    margin: 30px 0px;
    padding: 0 4%;
}

.subs .col-md-12{
    display: inline-flex;
    padding: 0 2% 0 0;
}
.subs h2{
    font-size: 34px;
    color: #eee;
    padding: 10px 0px 35px 0%;
}

.subs .col-md-12 .col-md-3.package{
    width: 280px;
    height: 360px;
    border-radius: 12px;
    background-color: #1A1D25;
    padding: 20px 20px;
    margin: 20px 0px 0 25px;
    text-align: center;
}
.subs .col-md-12 .col-md-3.package:hover{
    transform: scale(1.05);
    transition: .3s linear;
    background: linear-gradient(180deg, #F24060 0%, #D90917 100%);
opacity: 0.9;
}
.subs .col-md-12 .col-md-3.package p{
    font-size: 18px;
    padding: 0 0 10px 0;
    color: #fff;
    border-bottom: 3px solid #ccc;
}
.subs .col-md-12 .col-md-3.package p.per{
    padding: 0px 0;
    font-size: 34px;
    border-bottom: none;
}
.subs .col-md-12 .col-md-3.package p.per img{
    margin: -12px 0 0 0;
    padding: 0;
}

.subs .col-md-12 .col-md-3.package .listb{
    height: 300px;
    width: 100%;
    overflow: hidden;
}
.subs .col-md-12 .col-md-3.package .listb li{
    text-align: left;
    padding: 20px 0% 10px 0px;
    font-size: 18px;
    height: 50px;
    overflow: hidden;
}
.subs .col-md-12 .col-md-3.package .fa-close{
    color: #FFFFFF;
    font-size: 18px;
}
.subs .col-md-12 .col-md-3.package .fa-check{
    color: #FFFFFF;
    font-size: 16px;
}
.subs .col-md-3 li{
    text-align: left;
    color:#fff;
    list-style-type: none;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 20px;
    padding: 30px 0% 0px 0px;
    font-family: 'Futura PT';
}
.subs .col-md-3 li .fa{
color: #D90917;
padding: 0px 5px 0px 0px;
}
.subs a .btn_buy{
    color: #D90917;
    background-color: #fff;
    font-size: 18px;
    border-radius: 4px;
    text-align: center;
    padding: 10px 20px;
    width: 90%;
    margin: -70px 5% 0 5%;
}
.input_field_col .btn{
    margin: 20px 5% 10px 5%;
    background-color: #D90917;
    color: #fff;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    padding: 10px 20px;
    width: 90%;
}
/*update profile css*/
.update{
    color :#fff;
    padding: 10px 20px;
    width :40%;
    margin: 0px 30%;
}
.update p{
color:#fff;
font-size: 18px;
}
.update .col-md-12{
    padding: 0px 0% 0px 0%;
}
.update .col-md-12 input , .update .col-md-12 textarea {
    width: 100%;
    background-color: #211C22;
    border: none;
    outline: none;
    color: #fff;
}
.update .col-md-12 select{
    width: 100%;
    background-color: #211C22;
    border: none;
    outline: none;
    color: #fff;
}
.update .col-md-12 .btn{
    background-color: #D90917;
    border: none;
    padding: 12px 0px;
    text-align: center;
}
.contactus{
    padding: 3% 10%;
    background-image: url('./assets/Icons/colorful-abstract-nebula-space-background\ 1.png');
    background-size: cover;
}
.contactus .companyd{
    display: inline-block;
    width: 70%;
    margin: 10px 15%;
}
.contactus .companyd p{
    padding: 10px  0 0 0;
    margin: 0;
}
.contactus.quote h1{
    text-align: center;
    color: #fff;
    width: 50%;
    margin: 0px 25%;
    font-size: 24px;
}
.contactus h1{
    text-align: center;
    color: #fff;
    width: 50%;
    margin: 0px 25%;
    font-size: 36px;
}
.contactus.quote .quote-btn{
    background-color: #D90917;
    color :#fff;
    padding: 10px 20px;
    font-size: 22px;
    width: 40%;
    margin: 20px 30%;
}

.contactus p{
    padding: 10px 0px;
    text-align: center;
    color :#fff;
}

.update h1,.update p{
    text-align: center;
}

.contactus form{
    width: 70%;
    height: auto;
    margin: 10px 15%;
    padding: 10px 0;
    border-radius: 6px;
    background-size: cover;
    background-image: url('./assets/Icons/Rectangle\ 1266.png');
}
.contactus h2{
    text-align: center;
    padding: 20px;
    border:2px solid #D90917;
    color: #D90917;
    font-size: 30px;
    width: 40%;
    border-radius: 6px;
    margin: 30px 30%;
    background-color: transparent;
}
.contactus.quote h2{
    text-align: center;
    padding: 10px;
    border:2px solid #D90917;
    color: #D90917;
    font-size: 26px;
    width: 40%;
    border-radius: 6px;
    margin: 0px 30%;
    background-color: transparent;
}
.contactus h2 .fa{
transform: rotate(90deg);
padding: 0px 5px;
}
.contactus form .ch{
    display: inline-block;
    background-color: #0D0C0C;
    border-radius: 6px;
    padding:  10px 10px;
    border: 1px solid #ccc;
    height: auto;
    width :95%;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    margin: 10px 2.5% 0px 2.5%;
}
.contactus form .half{
    width: 95%;
    margin: 0px 2.5% 0px 2.5%;
    display: inline-flex;
}
.contactus form .half .ch{
    width: 49%;
    margin: 10px 1% 0px 0%;
}
.contactus form .playbtn{
    width: 30%;
    margin: 10px 35%;
    color :#fff;
    background-color: #D90917;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    padding: 13px 0px;
}

.contactus form .ch label{
color: #909196;
font-size: 14px;
}
.contactus form .ch input,textarea{
    width: 100%;
    background-color: #0D0C0C;
    border: none;
    outline: none;
    color: #fff;
    }
    .contactus form .ch input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 1; /* Firefox */
    }
    .contactus form .ch textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 1; /* Firefox */
    }
.payment{
    padding: 10px 15px;
    width: 76%;
    margin: 0px 12%;
}
.payment .paytm_option{
    padding: 20px 20px;
    width: 30%;
    background-color: #fff;
    border-radius: 12px;
}
.payment .paytm_option img{
    margin-left: -10px;
}
.payment .paytm_option ul li{
    padding: 20px 10px 5px 0px;
    border-bottom: 1.5px solid #ccc;
    font-family: sans-serif;
    letter-spacing: .5px;
    font-size: 20px;
    font-weight: 500;
}
.payment .paytm_option ul li .fa{
padding-right: 10px;
font-size: 20px;
}

.payment .row Link p{
    color: #fff;
    padding: 10px 0px;
    font-size: 25px;
}
.payment p .fa{
padding: 0 10px;
font-size: 20px;
}
.payment div.inner{
    display: inline-flex;
    margin: 10px 1% 10px 1%;
    padding: 0;
    background-color: #fff;
    width: 250px;
    border-radius: 4px;
    cursor: pointer;
}

.payment div img{
    width: 110px;
    height:100px;
    border-radius: 50%;
    padding: 10px 10px;
    margin: 0px 10px;
}

.payment h1{
    font-size: 22px;
    color: #fff;
    padding: 10px 0px;
}

.stripe{
    color: #fff;
}
.getuser .container .row .col-md-6 .row .col-md-12:nth-child(1) {
    display: inline-flex;
    width: 100%; 
    padding: 10px 0px 0px 10px;
}
/*media for 766 (Main 1) */
@media (max-width: 766px) {
    .contactus .companyd{
        display: inline-block;
        width: 100%;
        margin: 10px 0%;
    }
    .logg #otpless{
        margin: 10px 10% 0 10%;
    }
    .content_popup{
        background-color: #1B1B1B;
        width: 90%;
        height: auto;
        align-content: center;
        justify-content: center;
        margin: 20% 5%;
        color: #fff;
        padding: 20px;
        border-radius: 12px;
        position: absolute;
        z-index: 999999999;
    }
    .update{
        color :#fff;
        padding: 10px 20px;
        width :100%;
        margin: 0px 0%;
    }
    .update:before {
        width: 20%;
        right: 0;
        background: linear-gradient(-90deg, rgba(208,8,32,0) 0%, #D90917 68%);
        height: 4px;
        content: "";
        top: 14%;
        position: absolute;
        margin-top: -6px;
    }
    .update:after {
        width: 20%;
        left: 0;
        background: #D90917;
        background: linear-gradient(90deg, rgba(208,8,32,0) 0%, #D90917 68%);
        height: 4px;
        content: "";
        top: 14%;
        position: absolute;
        margin-top: -6px;
    }
    .install{
        border:1px solid #222;
        border-radius: 0;
        width: 100%;
        max-width: 100%;
        bottom: 0;
        height: 140px;
        min-height: 140px;
        color: #fff;
        padding: 10px 5px;
        background-color: #000;
        display: inline-flex;
        position: fixed;
        overflow: hidden;
        }
        .install a{
            width: 25%;
            border:1px solid #D90917;
            border-radius: 12px;
            margin:5px 0px;
            text-align: center;
            padding: 5px 10px;
            height :45px;
            background-color: #D90917;
            color: #fff;
            font-size: 20px;
        }
    .contactus.quote h1{
        text-align: center;
        color: #fff;
        width: 90%;
        margin: 0px 5%;
        font-size: 24px;
    }
    .contactus.quote .quote-btn{
        background-color: #D90917;
        color :#fff;
        padding: 10px 20px;
        font-size: 22px;
        width: 90%;
        margin: 20px 5%;
    }
    .contactus.quote h2{
        text-align: center;
        padding: 10px;
        border:2px solid #D90917;
        color: #D90917;
        font-size: 26px;
        width: 90%;
        border-radius: 6px;
        margin: 0px 5%;
        background-color: transparent;
    }
    .getuser{
        padding: 0px;
        height: auto;
        width: 100%;
        margin: 0px 0%;
    }
    .getuser .container{
        align-content: center;
        justify-content: center;
    }
    
    .getuser .container img#user{
        width: 20%;
        height:80px;
        margin: 10px 40%;
        padding: 10px;
        border:1px solid #D90917;
        border-radius: 50%;
        position: relative;
    } 
    .getuser .images{
        display: inline-flex;
        width: 100%;
        margin: 10px 0%;
        
    }
    .getuser .container button{
    background-color: #D90917;
    border-radius: 32px;
    width: 50%;
    margin: 20px 25%;
    }
    .getuser hr{
        height: 5px;
        background-color: #D90917;
        width: 20%;
        margin: 10px 40%; 
    }
    .getuser  p{
        font-size: 22px;
    }
    .getuser  h3{
    text-align: center;
    color:#fff;
    padding: 20px 0px 10px 0px;
    }
    .getuser .images a {
        width: 50%;
        border-radius: 0;
    }
    .getuser .images a img{
    width: 96%;
    margin: 1% 0% 0px 4%;
    height: 65px;
    border-radius: 0;
    }

    .contactus p{
        padding: 10px 0px;
        text-align: center;
        color :#fff;
        font-size: 18px;
    }
     
    .contactus.one h1{
        text-align: center;
        color :#fff;
        width: 50%;
        margin: 10px 25%;
        font-size: 28px;
        padding: 5px 0px;
    }
    .banner .banner_col{
        height:310px;
        width:100%;
        overflow: hidden;
        padding: 0;
        margin:10px 0px;
    }
    .banner .banner_col .swiper-slide{
        color:#fff;
        background: transparent;
        margin: 40px 0;
        height: 150px;
        opacity: 1;
        transition: .3s linear;
      }
    .banner .banner_col .swiper-slide-active {
        color:#fff;
        background: transparent;
        margin: 60px 0;
        transform: scale(2);
        z-index: 99;
        height: 115px;
        opacity: 1;
        transition: .3s linear;
    }
    
    .banner .banner_col #play_btn{
        width: 14%;
        height:60px;
        margin: -50px 43% 0px 43%;
        z-index: 1;
        opacity: 1;
        position: absolute;
      }
      .banner .banner_col #play_btn_trailer{
        width: 80px;
        height:80px;
        margin-left: 47.5%;
        margin-top: -5%;
        z-index: 999999;
        opacity: 1;
        position: absolute;
    }
   
    .banner .banner_col  .swiper-button-prev, .banner .banner_col  .swiper-button-next{
        padding: 10px;
        overflow:hidden;
       color: #D90917;
       margin: 28% 32% 10px 32%;
       z-index: 99999999;
       font-size: 12px;
     }
     .trailernews{
        height: auto;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;
     }
     .trailernews h1{
        text-align: center;
        color: #fff;
        margin:0px 0px 0px 0px;
        opacity: 1;
        font-size: 24px;
    }
    .trailernews p{
        margin: 10px 0px 10px 0px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 200;
    }
    .trailernews input{
        padding: 12px 20px;
        width: 90%;
        margin:10px 5%;
        border-radius: 6px;
        background-color: #111;
        outline: none;
        color: #fff;
        border: 1px solid #111;
    }
    .trailernews button{
       width: 60%;
       margin:10px 20%;
    }

    /*mobile device css for login screen*/
    .login{
        height: auto;
        width: 100%;
        padding: 0px;
        margin: 2% 0% 10% 0%;
        border-radius: 12px;
        border: none;
    }
    .login .row{
        width: 100%;
        height : 100%;
        padding: 0px;
        margin: 0;
        border:1px solid #E1448440;
        border-radius: 12px;
    }
    .login .row .col-md-6:nth-child(2){
        height: 100%;
        padding: 10px;
        border:1px solid #E1448440;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color:#E1448440;
    }
    .login .row .col-md-6:nth-child(2) .loginchild label{
    padding: 10px 0px 0px 10px;
    font-size: 18px;
    color: #ccc;
    }
    .login .row .col-md-6:nth-child(1){
        height: 350px;
    }
    .login .row .col-md-6:nth-child(1) h1{
        color: #D90917;
        font-size:5rem;
        text-align: center;
        padding: 0px 0px;
        margin-top: 0% 0px 0% 0px;
    }
    .payment{
        padding: 10px 15px;
        width: 100%;
        margin: 0px 0%;
    }
    .payment .paytm_option{
        padding: 0px 20px 20px 20px;
        width: 100%;
        background-color: #fff;
        border-radius: 12px;
    }
    .payment .paytm_option img{
        width: 120px;
        height: 100px;
        border-radius: 0;
    }

    .payment div.inner{
        display: inline-flex;
        margin: 10px 5% 10px 5%;
        background-color: #fff;
        width: 90%;
        border-radius: 4px;
    }
   
    .getuser .container .row .col-md-6 .col-md-12 .col-md-8 .username{
        margin: 0px 10px 10px 20px;
        font-size: 22px;
        
    }
    .getuser .container img{
        width: 50px;
        height: 54px;
        border-radius: 50%;
        position: relative;
    } 
    .getuser .container .row .col-md-6 .row .col-md-12:nth-child(1) {
        display: inline-flex;
        width: 100%;
        
        padding: 10px 0px 0px 10px;
    }
    .getuser .container .row .col-md-6 .row .col-md-12:nth-child(1) .col-md-3{
        
        padding: 0 0 0 20px;
    }

.tag{
    font-size: 22px;
    color:#fff;
    text-align: center;
    width: 100%;
    margin-top: 0px;
    
}
.msg{
    font-size: 22px;
    margin: 30px 0px;
}
.detail p{
    color: #fff;
    padding: 10px 0px;
    font-size: 16px;
}
.detail{
display: block;
}
.getuser{
    padding: 20px 10px;
    height: auto;
    
}
.manageuser{
    margin: 10px 0px;
    font-size: 24px;
}
.contactus{
    padding: 0px 0%;
}
.contactus .row .col-md-4{
    padding: 10px 0px 10px 10px;
}
.contactus .company .row{
    display:inline-flex;
    width: 100%;
}
.contactus .company .row .col-md-2 {
    border-bottom: 1px solid #D90917;
    width: 80px;
    padding: 0;
    margin: 10px 0px 0px 10px;
}
.contactus .company .row .col-md-1 {
display: none;
}
.contactus .col-md-8{
    padding: 5% 2%;
    
}
.contactus form{
    width: 100%;
    height: auto;
    margin: 10px 0%;
    border:2px solid #211C22;
    border-radius: 6px;
    background-color: #211C22;
}
 
.contactus.one h1{
    text-align: center;
    color :#fff;
    width: 50%;
    margin: 5px 25% 0px 25%;
    font-size: 28px;
    padding: 5px 0px;
}
.contactus form .btn{
    background-color: #D90917;
    color: #fff;
    padding: 5px;
    text-align: center;
    border-radius: 6px;
    width: 95%;
    margin: 10px 2.5%;
    font-size: 16px;
}
.contactus h2{
    text-align: center;
    padding: 10px 10px;
    border:2px solid #D90917;
    color: #D90917;
    font-size: 24px;
    width: 90%;
    border-radius: 6px;
    margin: 30px 5%;
    background-color: transparent;
}
}


@media (max-width: 599px) {
    .content-details .container .select{
        width:40%;
        color:#fff;
        margin: 10px 0 10px 10px;
    }
    .product_item_list{
        display: inline-flex;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        transition: 1s linear;
        margin: 0;
        height: 340px;
    }
    .product_item_list.header .product_item .product_item_image {
        margin: 0px 10px 0 0;
        background: none;
        padding: 0;
        box-shadow: none;
        width: 150px;
        height: auto;
    }
    /*content-details 600px*/
    .content-details{
        padding:0px;
        overflow: hidden;
        margin: 0;
        width: 100%;
    }
    .content-details .container .col-md-3.new_subs{
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        height: auto;
        width: 80%;
        margin: 5% 10%;
        padding: 10px;
    }
    .content-details .container .col-md-9 .tpbutton,.content-details .container .col-md-12 .tpbutton{
        position: absolute;
        z-index: 99999;
        cursor: pointer;
        width: 90%;
        height:auto;
        top: 8%;
        left: 0;
        margin: 0 5%;
    }
    .content-details .container{
        margin: 0;
        padding: 0;
        width: 100%;
        display: inline-block;
    }
    
    .content-details .container .col-md-12 img{
        width: 100%;
        background-size: cover;
        margin: 0px 0%;
        padding: 0;
    }
    .content-details .container .col-md-12  p{
        padding: 10px 0 0 10px;
        color:#ccc;
    }.content-details .container .col-md-12  p span{
        color:#fff;
    }
    .content-details .container .col-md-12  h1{
        padding: 0px 0 0 10px;
        color:#fff;
    }
    .content-details .container .col-md-12  ul{
        display: inline-flex;
        width: 100%;
        overflow-x: scroll;
    }.content-details .container .col-md-12  ul::-webkit-scrollbar{
        display: none;
    }
    .content-details .container .col-md-12  ul li{
        list-style-type: none;
        border: 1px solid #ccc;
        color: #fff;
        padding:6px 20px;
        margin: 10px 0px 0 10px;
    }
    .content-details .container .col-md-12  .buttons{
        display: flex;
        padding: 20px 10px;
    }
    .content-details .container .col-md-12  .buttons .fa{
        font-size: 16px;
        padding: 8px 10px;
        color: #D8000F;
        margin: 0 10px 0 0;
        border-radius: 2px;
        opacity: 1;
        background-color: #fff;
    }
    .content-details .container .col-md-12  .buttons .playbtn{
        background-color: #D8000F;
        display: inline-flex;
        border-radius: 2px;
        padding: 5px 0px 5px 5px;
        height: 45px;
        width: 260px;
        margin:0 10px 0 0;
        clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    }
    .content-details .container .col-md-12  .buttons span{
        padding: 5px 0 0 0;
    }
    .content-details .container .col-md-12  .buttons .watchbtn{
        background-color: #222;
        display: inline-flex;
        border-radius: 2px;
        padding: 5px 0px 5px 5px;
        height: 45px;
        width: 260px;
        margin:0 10px 0 0;
        clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    }
    .content-details .container .col-md-12 .season{
        width: 100%;
        display: inline-flex;
        overflow-x: scroll;
    }
    .content-details .container .col-md-12 .season .episode{
        width: 46%;
        margin: 0 0% 0 2%;
    }
    .content-details .container .col-md-12 .season .episode img{
        width: 100%;
        height: auto;
        margin: 10px 0px 10px 10px;
        
    }
    .content-details .container .col-md-12 .season .episode img:hover{
        border: 2px solid #ccc;
    }
    .content-details .container .col-md-12 .season .episode p{
       color: #fff;
       padding: 0px 0 15px 10px;
       margin: 0;
    }
    
    .ticket{
        padding: 10px 0 10px 2.5%;
        width: 97.5%;
    }
    .click_to_subsc{
        border-bottom: 0;
        padding: 10px;
    }
    .product_item_image:hover{
        transform: scale(1);
        transition: none;
    }
    .product_item_image:hover p{
        display: block;
    }
    .policy{
        width: 100%;
        display: inline-block;
        padding: 0px 0 20px 0;
    }
    .policy_area {
        width: 100%;
        max-width:100%;
        margin: 0 auto;
        display: table;
    }
    .policy_area_col{
        width: 100%;
        display: inline-block;
        margin-bottom: 30px;
    }
    .policy_heading{
        width: 100%;
        display: inline-block;
        position: relative;
    }
    
    .policy_heading h2,h5{
        margin: 0 auto;
        font-size: 32px;
        font-weight: 900;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 0px;
        line-height: 50px;
        position: relative;
        width: 100%;
        padding: 20px 0px 0 0px;
    }
    
    .policy_heading h2 span{
        color: #D90917;
    }
    /*sundaram*/
    .policy_area_col p{
        width: 98%;
        display: inline-block;
        text-align: left;
        font-size: 17px;
        font-weight: 300;
        border-left: 2px solid #D8000F;
        color: #fff;
        margin:0px 1% 0 1%;
        padding: 0px 0px 15px 10px; 
    }
    .policy_area_col h5{
    color:#fff;
    padding: 10px 0px 0px 30px;
    }
    .policy_area h3{
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #D90917;
    }
    
    
    .banner .banner_col2 .upcoming {
        width: 98%;
        padding: 20px 0;
        height:auto;
        margin:0px 1%;
    }
    
    .banner .banner_col2 .upcoming .upinner{
        width: 100%;
        margin: 0 0%;
        display: inline-flex;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch{
        margin: 0;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(1){
        width: 40%;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(2){
        width: 60%;
        display: inline-block;
        padding: 10px 5px;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(1) img{
        margin:30px 2% 0 3%;
        border-radius: 12px;
        width: 95%;
        height: 200px;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch h2{
        font-size: 20px;
        padding: 25px 0 5px 0;
        font-weight: bold;
    }
    
    .banner .banner_col2 .upcoming .upinner .upinnerch span.date{
        position: absolute;    
        background-image: url('./assets/Icons/Rectangle\ 1218.png');
        background-size: cover;
        width: 160px;
        height: 60px;
        text-align: center;
        padding: 5px 10px;
        font-size: 10px;
        right:0;
        margin: -21px -24px 0 0px;
        color: #fff;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch p{
        font-size: 15px;
        font-weight: normal;
        padding: 5px 0 5px 0;
        color: #ccc;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch p.ds{
        height: 73px;
        padding: 5px 0;
        overflow: hidden;
    }

    .banner .banner_col2 .upcoming .swiper-slide{
        color:#fff;
        background: transparent;
        margin: 50px 0 0 0;
        background-color: #1C1C1C;
        border-radius: 12px;
        height: 320px;
        opacity: 1;
        transition: .3s linear;
      }
    .banner .banner_col2 .upcoming .swiper-slide-active {
        color:#fff;
        margin: 0px 0;
        border-radius: 12px;
        z-index: 99;
        background-color:#302222;        
        opacity: 1;
        transition: .3s linear;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch .buttons{
        display: inline-block;
        margin: 5px 0 0 0;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch .buttons img{
        padding: 10px 10px 0 0;
    }

    .banner .banner_col2 .upcoming .upinner .upinnerch .buttons .fa{
        font-size: 16px;
        padding: 8px 10px;
        color: #D8000F;
        margin: 0 10px 0 0;
        border-radius: 2px;
        opacity: 1;
        background-color: #fff;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch .buttons .playbtn{
        background-color: #D8000F;
        display: inline-flex;
        border-radius: 2px;
        padding: 5px 0px 5px 5px;
        letter-spacing: 1px;
        height: 45px;
        width: 90%;
        margin:0 5% 0 2%;
        clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch .buttons span{
        padding: 5px 0 0 0;
        font-size: 16px;
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch .buttons .watchbtn{
        display: inline-flex;
        background-size: cover;
        background-image: url('./assets/Icons/Rectangle\ 1214\ \(1\).png');
        border-radius: 2px;
        padding: 5px 0px 0px 5px;
        letter-spacing: 1px;
        height: 50px;
        width: 90%;
        margin:10px 5% 0 2%;
        clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    }
    .banner .banner_col2 .upcoming .upinner .upinnerch .buttons .watchbtn .fa{
        color: #fff;
        background-color: #D90917;
    }
    .banner .banner_col2 .bottom{
        top:50%;
        padding: 15px 0px;
        width: 80%;
        overflow: hidden;
        margin: 0px 0 0 0%;
        position: absolute;
        display: inline-flex;
    }
    .banner .banner_col2 .bottom p{
        font-size: 18px;
        width: 20%;
        padding: 0 10px;
        display: inline-flex;
    }
    .banner .banner_col2 .bottom .line{
        width: 75%;
        height: 2px;
        margin: 10px 0;
        background-color: #fff;
    }
    .banner .banner_col2  .swiper-button-prev{
        top:95%;
        left:80%;
      }
      .banner .banner_col2 .swiper-button-next{
        top:95%;
        right:0%;
      }
    
    /*subscription css for mobile devices*/
    .subs{
        padding: 10px 0%;
    }
    .subs .row{
        align-content: center;
        justify-content: center;
        margin: 30px 0px;
        padding: 0 0%;
    }
    
    .subs .col-md-12{
        display: inline-block;
        padding: 0 0% 0 0;
    }
    .subs h2{
        font-size: 34px;
        color: #eee;
        padding: 10px 0px 35px 0%;
    }
    
    .subs .col-md-12 .col-md-3.package{
        width: 80%;
        border-radius: 12px;
        background-color: #1A1D25;
        padding: 20px 20px;
        margin: 0px 10% 50px 10%;
        height: 400px;
        text-align: center;
    }
    .subs .col-md-12 .col-md-3.package:hover{
        transform: scale(1.05);
        transition: .3s linear;
        background: linear-gradient(180deg, #F24060 0%, #D90917 100%);
        opacity: 0.9;
    }
    .subs .col-md-12 .col-md-3.package p{
        font-size: 26px;
        padding: 0 0 10px 0;
        color: #fff;
        border-bottom: 3px solid #ccc;
    }
    .subs .col-md-12 .col-md-3.package p.per{
        padding: 0px 0;
        font-size: 34px;
        border-bottom: none;
    }
    .subs .col-md-12 .col-md-3.package p.per img{
        margin: -12px 0 0 0;
        padding: 0;
    }
    .subs .col-md-12 .col-md-3.package li{
        text-align: left;
        padding: 10px 0% 10px 0px;
        font-size: 20px;
    }
    .subs .col-md-12 .col-md-3.package .fa-close{
        color: #FFFFFF;
        font-size: 18px;
    }
    .subs .col-md-12 .col-md-3.package .fa-check{
        color: #FFFFFF;
        font-size: 16px;
    }
    .subs .col-md-3 li{
        text-align: left;
        color:#fff;
        list-style-type: none;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 20px;
        padding: 30px 0% 0px 0px;
        font-family: 'Futura PT';
    }
    .subs .col-md-3 li .fa{
    color: #D90917;
    padding: 0px 5px 0px 0px;
    }
    .subs .col-md-3 .btn_buy{
        margin: -50px 10% 5px 10%;
        color: #D90917;
        background-color: #fff;
        font-size: 18px;
        border-radius: 4px;
        text-align: center;
        padding: 10px 20px;
        width: 80%;
    }

    .inner_about {
        width: 100%;
        display: inline-block;
        margin: 20px 0 20px 0;
    }
    .inner_about .container .row .col-md-6:nth-child(2) img{
        width: 80%;
        margin: 5% 10% 0 10%;
    }
    .inner_about .container .row .col-md-6:nth-child(1){
        padding: 5% 0 2% 5%;
    }
    .inner_about .container .row .col-md-6 h1{
        color: #fff;
        padding: 10px 0;
        font-size: 45px;
    }
    .inner_about .container .row .col-md-6 p{
        color: #ccc;
        font-size: 18px;
        padding: 10px 0;
    }
    .container.add{
        width: 100%;    
        margin: 20px 0px 20px 0px;
        background-color: #070101;
        padding: 0;
        overflow: hidden;
    }
    .container.add .col-md-6:nth-child(1){
        width: 100%;
        height: 100%;
        margin:0 -8% 0 0;
        padding: 0px 0 0 0px;
        z-index: 9999;
    }
    .container.add .col-md-6:nth-child(1) img{
        width: 95%;
        height: auto;
        padding: 0;
        margin: 0 0% 0 5%;
    }
    .container.add .col-md-6:nth-child(2){
    display: inline-block;
    background-image: url('./assets/Icons/Rectangle\ 5.png');
    background-size: cover;
    padding: 0px 0px 40px 6%;
    height:auto;
    margin:4% 0;
    z-index: 1;
    }
    
    .container.add .col-md-6 h2{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
    padding: 10px 0px 0px 0px;
    float: left;
    }
    .add .col-md-6 p{
        color: #fff;
        margin: 80px 0px 0px 0px;
        padding: 10px 0px;
        font-size: 22px;
        font-weight: normal;
    }
    .add .col-md-6 h3{
        color: #fff;
        margin: 0px 0px 0px 0px;
        padding: 10px 0px;
        font-size: 26px;
    }
    .add .col-md-6 .images a{
        padding: 0;
        margin:0;
    }
    .add .col-md-6 .images a img{
        padding: 0;
        margin: 0 10px 0px 0px;
        width: 95%;
        height:auto;
    }
.login .logg{
    width: 95%;
    border-radius: 12px;
    margin: 5% 2.5%;
    padding: 20px 10px;
    display: inline-block;
    height: auto;
    border: 2px solid #D90917;
}
.login .logg .otp{
    display: inline-flex;
    width: 80%;
    margin: 10px 10% 10px 2%;
    border: 1px solid #fff;
}
.login .logg .otp input[type="text"]{
    width: 40px;
    height: 50px;
    font-size: 26px;
    padding: 5px 12px;
}
.login .logg .social-icons{
    width: 50%;
    display: inline-flex;
    margin: 10px 25%;
}
    .product_item_list{
        display: inline-flex;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        transition: 1s linear;
        margin: 0;
        height: 220px;
    }
    header {
        width: 100%;
        position: relative;
        height: 70px;
        background: transparent;
        top: 0;
        right:0;
        display: inline-flex;
        padding: 10px 0;

    }
    .header_btn {
        float: right;
        margin-top: 0px;
        padding: 0 0px 0px 0px;
        margin-right: 0px;
        margin-left: 4%;
    }
    .header_btn ul li a.userh{
        display: block;
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        line-height: 40px;
        width: 100%;
        position: relative;
        text-align: center;
        text-decoration: none;
        padding: 0;
        margin: 3px 0px 0 80px;
    }
    header .right_nav{
        display: inline-flex;
        width: 100%;
        padding: 0;
        border-bottom: 1px solid transparent;
        margin: 0;
    }
    header .right_nav .mobile_primary{
        width: 40px;
        right: 0;
        height: 40px;
        margin:10px 0 0 25px;
        padding: 0;
        overflow: hidden;
    }
    header .right_nav .mobile_primary img{
        width: 30px;
        height: 30px;
        padding: 0 0 0 0px;
    }
    header .right_nav .mobile_primary ul{
        display: none;
        width: 100%;
    }
    header .right_nav .mobile_primary ul.active{
        width: 100%;
        height: 110vh;
        z-index: 999999;
        margin: 0;
        overflow: hidden;
        right:0;
        top:0;
        background-color: #D8000F;
        position: fixed;
        display: inline-block;
    }
    header .right_nav .mobile_primary ul.active img{
        right: 0;
        position: absolute;
        top:0;
        margin: 10px;

    }
    header .right_nav .mobile_primary ul.active li a{
        display: block;
        font-size: 24px;
        outline: none;
        text-decoration: none;
        width: 80%;
        margin: 0 20% 0 0;
        color:#fff;
        padding: 15px 0 0 15px;
    }

    .product{
        padding: 0px 0px 10px 0px;
        margin:0;
    }
    .banner{
        width: 100%;
        margin:0;
        overflow: hidden;
    }
    /*css for 600px screens banner home*/
    .banner .banner_col1 {
        height: auto;
        width: 100%;
        padding:0;
        margin: 0;
    }
    .banner .banner_col1 img{
        width: 100%;
        margin: 10px 0;
    }
    .banner .banner_col1 .banner_btm{
        display: inline-block;
        width: 100%;
        height: 200px;
        margin: 10px 0;
    }
    .banner .banner_col1 .banner_btm h2{
        color: #fff;
        padding: 10px;
        margin: 0px 0 0 0;
        width: 100%;
        font-size: 22px;
    }
    .banner .banner_col1 .banner_btm a{
        width: 100%;
        margin: 0;
    }
    .banner .banner_col1 .banner_btm a img{
        width: 50%;
        margin: -60px 5% 0 2%;
        padding: 0;
    }
    
    .banner .banner_col1 .homeslider{
        width: 100%;
        margin: 0 0% 0 0%;
        display: inline-block;    
    }
    .banner .banner_col1 .homeslider .swiper {
        padding: 0px 10px 0px 10px;
        margin: 0 0%;
        width:100%;
        overflow:hidden;
        height:340px;
        display: flex;
    }  
    .banner .banner_col1 .homeslider .swiper .swiper-slide{
        height: 250px;
    }

    .banner .banner_col1 .homeslider .detailed{
        display: none;
    }
    .banner .banner_col1 .homeslider .bottom{
        top:49%;
        padding: 10px 0px;
        width: 70%;
        margin: 0 0% 0 2%;
        position: absolute;
        display: inline-flex;
    }
    .banner .banner_col1 .bottom p{
        font-size: 16px;
        width: 20%;
        padding: 10px 0;
        display: inline-flex;
    }
    .banner .banner_col1 .bottom .line{
        width: 75%;
        height: 2px;
        margin: 15px 0;
        background-color: #fff;
    }
    .banner .banner_col1  .swiper-button-prev{
        position: absolute;
        background-image: url('./assets/Icons/Left arrow.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        height:30px;
        width:30px;
        top:85%;
        left:80%;
        padding: 10px; 
        overflow:hidden;
      }
      .banner .banner_col1 .swiper-button-next{
        position: absolute;
        background-image: url('./assets/Icons/right arrow.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        height:30px;
        width:30px;
        top:85%;
        right:0%;
        padding: 10px; 
        overflow:hidden;
      }

    /*css for trailer home scree 600px*/
    .trailer h1{
        color: #fff;
        font-size: 22px;
        padding: 10px 0px 10px 15px;
        position: absolute;
    }
    .trailer p{
        padding: 35px 0 0 15px;
        color: #ccc;
        font-size: 18px;
        position: absolute;
    }
    .banner .banner_col {
        overflow: hidden;
        width: 100%;
        padding:20px 10px;
        margin: 0;
    }
    .banner .banner_col .swiper-slide {
        height: 220px;        
        margin: 14% 0 0px 0;
        overflow: hidden;
        border-radius: 12px;
      }
      .banner .banner_col .swiper-slide-active {
        border:2px solid #D90917;
        transform: scale(1);
      }

     .banner .banner_col .swiper-slide a,.banner .banner_col .swiper-slide-active a{
        width: 100%;
        height: 100%;
     }
      .banner .banner_col .swiper-slide a img{
        width: 100%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        height: 100%;
        border-radius: 12px;
        padding: 0px;
        margin:0px;
      }
      .banner .banner_col .swiper-slide-active a img {
        width: 100%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        height: 100%;
        border-radius: 12px;
      }
      .banner .banner_col  .swiper-button-prev{
        position: absolute;
        background-image: url('./assets/Icons/Left arrow.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        height: 30px;
        width:30px;
        top:-38%;
        left:45%;
        padding: 10px; 
        overflow:hidden;
      }
      .banner .banner_col .swiper-button-next{
        position: absolute;
        background-image: url('./assets/Icons/right\ arrow.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        height: 30px;
        width:30px;
        top:-38%;
        left:57%;
        padding: 10px; 
        overflow:hidden;
      }


/*content details css 600px*/
/*css for 600px frequent*/
.frequent{
    margin:0;
    padding: 0 20px;
}
.frequent .row .col-md-4 .ask{
    width: 100%;
    margin:20px 0 10px 0;
    padding: 10% 30px;
    border-radius: 12px;
    background-color: #111;
    height: 300px;
}
.frequent .row .col-md-4 .ask p{
    color: #ccc;
    padding: 0;
}
.frequent .row .col-md-4 .ask h1{
    padding: 10px 0;
    text-align: left;
}
.frequent .row .col-md-4 .ask .playbtn{
    width: 60%;
    margin: 20px 40% 10px 0%;
    color :#fff;
    background-color: #D90917;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
    padding: 13px 0px;
}
.frequent .row .col-md-8{
    padding: 0px;
    display: inline-block;
    margin: 0px 0%;    
}
.frequent .row .col-md-8 .freq-child{
    display: inline-flex;
    width: 100%;
    margin: 15px 0% 0 0%;
}

.frequent .row .col-md-8 .hidden{
    height: 0px;
    width: 100%;
    padding: 0px;
    margin: 0px 0 0 0;
    background-color: #222;
    color:#ccc;
    overflow: hidden;
    transition: .3s linear;
}
.frequent .row .col-md-8 .hidden p{
padding: 10px 15px;
color: #ccc;
}
.frequent .row .col-md-8 span{
    width: 90%;
    color: #fff;
    padding: 13px 20px;
    background-color: #222;

}
.frequent .row .col-md-8 .fa{
    width: 10%;
    padding: 18px 0px 0 10px;
    background-color: #222;
    color: #fff;
}
.frequent .row .col-md-8 .fa-minus{
    display: none;
}



    
    .content_popup{
        background-color: #111;
        width: 90%;
        height: auto;
        align-content: center;
        justify-content: center;
        margin: 40% 5%;
        color: #fff;
        padding: 20px;
        border-radius: 12px;
        position: absolute;
        z-index: 999999999;
    }
    .content_popup h2{
        color:#D90917;
        font-size: 32px;
        text-align: center;
        margin: 20px 0px;
    }
    .content_popup p{
    color: #ccc;
    text-align: center;
    width: 90%;
    font-size: 16px;
    margin: 0px 5% 10px 5%;
    }
    .content_popup p.head{
        color: #fff;
        font-size: 19px;
        width: 95%;
        margin: 0px .5%;
    }
    .content_popup .buttons{
        display: inline-flex;
        width: auto;
        margin: 20px 5%;
        width:90%;
    }
   
.banner-content h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
}

.btn {
    line-height: 40px;
    font-size: 14px;
    max-width: 130px;
}

a.logo span {
    font-size: 21px;
}

.banner-content h4 {
    font-size: 18px;
    letter-spacing: 10px;
}
.banner-content h3 {
    font-size: 28px;
    line-height: 30px;
    padding-bottom: 12px;
}
.banner-content h3:after {
    height: 25px;
} 

    .product_category_heading span{
        display: none;
    }
    .category_small_



.toggle_search span img{
    width: 100%;
}
.banner-content h1:after {
    width: 200px;
    height: 33px;
    margin-left: -100px;
    background-size: 200px;
}
.banner-content h1 {
    font-size: 30px;
    line-height: 31px;
    padding-top: 50px;
}
.banner_subsc_btn {
    font-size: 14px;
    padding: 0 25px;
    line-height: 41px;
}
.banner_subsc_btn span {
    width: 20px;
}
.banner_subsc_btn span img{
    width: 100%;
}
.sec_heading h2 {
    font-size: 26px;
    padding-bottom: 24px;
}
.sec_heading h2:after {
    width: 100%;
    height: 15px;
    margin-left: -109px;
    background-size: 100%;
}

.product_category_heading h4 {
    font-size: 18px;
}
.product_category_heading h4 {
    margin-top: 0px;
}
.explore_btn span {
    display: none;
}

.explore_btn {
    padding: 0 10px;
    line-height: 33px;
    font-size: 14px;
}

.product_item h3 {
    font-size: 14px;
    margin-top: 10px;
}
.product_category { /*main alignment of all contents*/
    margin: 10px 0 0  0;
}

.toggle_search_open_area input[type=text] {
    line-height: 40px;
    padding: 0 40px 0 20px;
    font-size: 15px;
}
.toggle_search_open_area input[type=submit] {
    top: 10px;
    right: 0;
    width: 20px;
    background-size: 20px;
}
.toggle_close {
    top: -40px;
    right: 0px;
    width: 20px;
    height: 23px;
    background-size: 20px;
}
.toggle_search_open {
padding: 20px 0px;
}

.toggle_search_open .product .container{
    width :100%;
    margin: 20px 0px;
    padding: 0px;
}
.toggle_search_open .product .container a{
    margin:0px 10px 0px 0px;
    padding: 0px;
}


.product_item_image img{
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

.toggle_search_open_area {
    margin-top: 30px;
    height: auto;
}
.newsletter {
    width: 100%;
    margin: 20px 0;
    padding: 70px 0;
}
.homelogo{
    display: none;
}
footer{
    overflow: hidden;
}
.footer_logo {
    width: 200px;
    margin-bottom: 20px;
}
.footer_download h3 {
    font-size: 20px;
    margin-bottom: 20px;
}
.footer_area h2 {
    font-size: 20px;
}
ul.quick_link li a {
    font-size: 16px;
}
footer .newsletter_form input[type=text] {
    line-height: 40px;
    padding: 0 20px;
    font-size: 15px;
}
footer .newsletter_form input[type=submit] {
    width: 100%;
    line-height: 40px;
    font-size: 16px;
    position: relative;
    margin-top: 15px;
}
ul.social_icon li a i {
    font-size: 20px;
}
ul.social_icon li {
    margin: 0 15px;
}
footer .newsletter_form {
    margin-bottom: 30px;
}
.last_footer p {
    font-size: 16px;
    padding: 15px 0;
}
.last_footer {
    margin-top: 40px;
}
.download_app a {
    width: 150px;
}
.download_app a img{
    width: 100%;
}
.newsletter h3 {
    font-size: 24px;
}
.newsletter h2 {
    font-size: 20px;
    margin-bottom: 20px;
}
.newsletter_form input[type=text] {
    line-height: 40px;
    padding: 0 20px;
    font-size: 15px;
}
.newsletter_form input[type=submit]{
    width: 100%;
    line-height: 40px;
    font-size: 16px;
    position: relative;
    margin-top: 15px;
}
.newsletter_form {
    width: 100%;
}
.download_app {
    margin: 30px auto 0;
}
.play_icon {
    background-size: 46px;
}
.product_item_list a p{
    color:#fff;
    text-align: center;
    padding: 5x 10px 0px 10px;
    font-size:16px;
}

.product_item_image{
	width: 150px;
	position: relative;
    height: 200px;
    margin:0px 0px 0px 0px;
}
.product_item_image.upcoming{
	width: 190px;
	position: relative;
    height: 140px;
    padding: 0;
    margin:10px 0px 0px 5px;
}
.product_item_image_overlay {
    padding: 10px;
}
.slide{
    height: 30vh;
}

.login_inner_area {
    width: 100%;
}
.logincontent {
    padding: 13px;
}
.content_inner_area ul li {
    font-size: 15px;
    margin: 31px 0;
    text-align: center;
    padding-left: 0;
    padding-top: 13px;
}
.content_inner_area ul li:after {
    top: -13px;
    width: 18px;
    background-size: 18px;
    height: 18px;
    left: 50%;
    margin-left: -9px;
}
.content_inner_area h3 {
    font-size: 16px;
    margin-top: 0;
}
.forgot {
    font-size: 16px;
    line-height: 50px;
    font-weight: 400;
}
ul.social_connect li {
    margin-right: 15px;
}
.input_field_col input[type="submit"] {
    font-size: 14px;
}
.flip_btn {
    display: table;
    margin: 0 auto 25px;
    width: unset;
    font-size: 14px;
    line-height: 38px;
}
ul.social_connect {
    margin: 0 auto 20px;
}
.input_field_col p {
    font-size: 12px;
}
.form-group label {
    font-size: 13px;
}
.form-group label:before {
    margin-right: 7px;
}

.form-group label span{
    font-size: 13px;
}
.banner.inner_banner .slick-dots {
    bottom: 40px;
}
.footer_download a{
    width: 50%;
}
.banner_btn_area:before {
    width: 24%;
}
.banner_btn_area:after {
    width: 24%;
}
.membership_col h4:after{
    display: none;
}
.membership_col h4:before{
    display: none;
}
.membership_col {
    padding: 30px 15px;
}
.membership_col:after {
    width: 25px;
    height: 25px;
    background-size: 25px;
}
.membership_col p {
    font-size: 14px;
}
.membership_col h5 {
    margin: 15px auto 0;
    font-size: 30px;
}
.membership_col h4 {
    font-size: 26px;
}
.membership_col h4 span {
    top: -5px;
    margin-right: 6px;
}
.inner_subcribe {
    width: 100%;
    display: inline-block;
    padding: 40px 0 0 0;
}
ul.subscribe_info {
    margin: 30px auto 0;
}
.membership {
    margin-top: 50px;
}
.membership h2 {
    font-size: 22px;
    margin-bottom: 30px;
}
ul.subscribe_info li {
    font-size: 14px;
}
.membership ul li {
    width: 100%;
}


/*ritam*/
.main {
    width: 100%;
}
.item_info h2 {
    font-size: 26px;
    margin-bottom: 25px;
}
.item_info_col h3 {
    font-size: 22px;
    line-height: 22px;
}
.item_info ul li a {
    font-size: 12px;
    line-height: 26px;
    padding: 0 10px;
}
.item_info ul li {
    margin-right: 10px;
    margin-bottom: 12px;
}
.item_info ul {
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.details .slick-initialized .slick-slide {
    margin: 0 5px;
}
.slider_sub_image_col h4 {
    font-size: 16px;
    margin-top: 8px;
}
.slider_sub_image_col p {
    font-size: 12px;
    margin-right: 6px;
    padding-left: 9px;
}
ul.video_category {
    padding: 10px;
}
.video-play-btn {
    height: 48px;
    width: 42px;
    background-size: 42px;
}
ul.video_category li {
    padding: 0 10px;
    line-height: 22px;
    font-size: 14px;
    margin-right: 10px;
}
.details {
    margin-top: 150px;
}
.item_info {
    padding-top: 20px;
}
  
footer {
    width: 100%;
    display: inline-block;
    margin:20px 0% 0 0%;
    padding: 20px 0 0 0; 
}
/*ritam*/
}
@media  (max-width: 380px){
    .logg #otpless{
        margin: 10px 5% 0 5%;
    }
    .header_btn {
        float: right;
        margin-top: 0px;
        padding: 0 0px 0px 0px;
        margin-right: 0px;
        margin-left: 0%;
    }
    .header_btn ul li a.userh{
        display: block;
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        line-height: 40px;
        width: 100%;
        position: relative;
        text-align: center;
        text-decoration: none;
        padding: 0;
        margin: 3px 0px 0 60px;
    }
    .subs .col-md-9 .col-md-3.package{
        width:100px;
        display: inline-block;
        border-radius: 12px;
        background-color: #1A1D25;
        padding: 10px 5px 30px 5px;
        margin: 0px 10px 0px 0%;
        text-align: center;
    }
    .subs .col-md-9 .col-md-3.package:hover{
        transform: none;
        background: linear-gradient(180deg, #F24060 0%, #D90917 100%);
    }
    .subs .col-md-9 .col-md-3.package p{
        font-size: 20px;
        padding: 0 0 10px 0;
        color: #fff;
        border-bottom: 3px solid #ccc;
    }
    .subs .col-md-9 .col-md-3.package p.per{
        padding: 0px 0;
        font-size:22px;
        border-bottom: none;
    }
    .subs .col-md-9 .col-md-3.package p.per img{
        margin: -12px 0 0 0;
        padding: 0;
    }
    .subs .col-md-9 .col-md-3.package li{
        text-align: center;
        padding: 20px 0% 10px 0px;
    }
    .subs .col-md-9 .col-md-3.package .fa-close{
        color: #FFFFFF;
        font-size: 18px;
    }
    .subs .col-md-9 .col-md-3.package .fa-check{
        color: #FFFFFF;
        font-size: 18px;
    }
    .subs .row .col-md-9 .col-md-3 .btn_buy{
        margin: 10px 0;  
        background-color: #D90917;
        background-color: #fff;
        font-size: 12px;
        border-radius: 4px;
        z-index: 99999;
        position: absolute;
        text-align: center;
        padding: 10px 0px;        
        width:65px;
    }
    .contactus.one h1{
        text-align: center; 
        color :#fff;
        width: 50%;
        margin: -30px 25% 0px 25%;
        font-size: 28px;
        padding: 5px 0px;
    }
    .getuser .container img#user{
        width: 24%;
        height: 85px;
        margin: 10px 38%;
        padding: 10px;
        border:1px solid #D90917;
        border-radius: 50%;
        position: relative;
    } 
   
    .banner .banner_col #play_btn{
        width: 14%;
        height:55px;
        margin: -50px 43% 0px 43%;
        z-index: 9;
        opacity: 1;
        position: absolute;
      }
      .banner .banner_col  .swiper-button-prev, .banner .banner_col  .swiper-button-next{
        padding: 10px;
        overflow:hidden;
       color: #D90917;
       margin: 30% 30% 10px 30%;
       z-index: 99999999;
       font-size: 12px;
     }
     .banner .banner_col1 .homeslider .bottom{
        top:53%;
        padding: 10px 0px;
        width: 70%;
        margin: 0 0% 0 4%;
        position: absolute;
        display: inline-flex;
    }
    .banner .banner_col  .swiper-button-prev{
        top:-38%;
        left:49%;
      }
      .banner .banner_col .swiper-button-next{
        top:-38%;
        left:60%;
      }
       /*css for trailer home screen 380px*/
    .trailer h1{
        color: #fff;
        font-size: 20px;
        padding: 10px 0px 10px 15px;
        position: absolute;
    }
    .trailer p{
        padding: 35px 0 0 15px;
        color: #ccc;
        font-size: 17px;
        position: absolute;
    }
}

@media screen and (min-height: 800px){
    .banner .banner_col1 .homeslider .bottom{
        top:43%;
        padding: 10px 0px;
        width: 70%;
        margin: 0 0% 0 3%;
        position: absolute;
        display: inline-flex;
    }
}
@media screen and (min-height: 1000px){
    /*css for 1000px screen upcoming slider*/
   /*css for upcoming slider*/
   .product_item_list.header{
    height: 340px;
   }
   .product_item_list.header .product_item .product_item_image {
    margin: 0px 10px 0 0;
    background: none;
    padding: 0;
    box-shadow: none;
    width: 180px;
    height: auto;
}

.banner .banner_col2 .upcoming {
    width: 100%;
    padding: 20px 0;
    height: 600px;
    margin:-20px 0 0 0;
}

.banner .banner_col2 .upcoming .upinner{
    width: 100%;
    display: inline-flex;
}
.banner .banner_col2 .upcoming .upinner .upinnerch{
    margin: 0;
}
.banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(1){
    width: 50%;
}
.banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(2){
    width: 50%;
    display: inline-block;
    padding: 10px 15px;
}
.banner .banner_col2 .upcoming .upinner .upinnerch:nth-child(1) img{
    margin: -20px 2% 0 8%;
    border-radius: 12px;
    width: 90%;
    height: 300px;
}
.banner .banner_col2 .upcoming .upinner .upinnerch h2{
    font-size: 20px;
    padding: 30px 0 5px 0;
    font-weight: bold;
}

.banner .banner_col2 .upcoming .upinner .upinnerch span.date{
    position: absolute;    
    background-image: url('./assets/Icons/Rectangle\ 1218.png');
    background-size: cover;
    width: 160px;
    height: 55px;
    text-align: center;
    padding: 5px 10px;
    font-size: 10px;
    right:0;
    margin: -21px -24px 0 0px;
    color: #fff;
}
.banner .banner_col2 .upcoming .upinner .upinnerch p{
    font-size: 15px;
    height: 50px;
    overflow: hidden;
    font-weight: normal;
    padding: 5px 0 5px 0;
    color: #ccc;
}

.banner .banner_col2 .upcoming .swiper-slide{
    color:#fff;
    background: transparent;
    margin: 160px 0 0 0;
    background-color: #1C1C1C;
    border-radius: 12px;
    height: 300px;
    opacity: 1;
    transition: .3s linear;
  }
.banner .banner_col2 .upcoming .swiper-slide-active {
    color:#fff;
    margin: 80px 0;
    border-radius: 12px;
    z-index: 99;
    background-color:#302222;
    opacity: 1;
    transition: .3s linear;
}
.banner .banner_col2  .swiper-button-prev{
    position: absolute;
    background-image: url('./assets/Icons/Left arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 35px;
    width:35px;
    top:95%;
    left:64%;
    padding: 10px; 
    overflow:hidden;
  }
  .banner .banner_col2 .swiper-button-next{
    position: absolute;
    background-image: url('./assets/Icons/right arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 35px;
    width:35px;
    top:95%;
    right:27%;
    padding: 10px; 
    overflow:hidden;
  }

.banner .banner_col2 .bottom{
    top:40%;
    padding: 15px 0px;
    width: 35%;
    margin: 0px 0 0 26%;
    position: absolute;
    display: inline-flex;
}
.banner .banner_col2 .bottom p{
    font-size: 18px;
    width: 20%;
    display: inline-flex;
}
.banner .banner_col2 .bottom .line{
    width: 80%;
    height: 2px;
    margin: 15px 0;
    background-color: #fff;
}
.banner .banner_col2 .upcoming .upinner .upinnerch .buttons{
    display: inline-block;
    margin: 10px 0 0 0;
}
.banner .banner_col2 .upcoming .upinner .upinnerch .buttons img{
    margin: 0 0 5px 0;
}

.subs{
    padding: 10px 0%;
}
.subs .row{
    align-content: center;
    justify-content: center;
    margin: 30px 0px;
    padding: 0 2%;
}

.subs .col-md-12{
    display: flex;
    padding: 0 0% 0 0;
}
.subs h2{
    font-size: 34px;
    color: #eee;
    padding: 10px 0px 35px 0%;
}

.subs .col-md-12 .col-md-3.package{
    width: 170px;
    border-radius: 12px;
    background-color: #1A1D25;
    padding: 20px 5px;
    margin: 0 0px 0 25px;
    text-align: center;
}
.subs .col-md-12 .col-md-3.package:hover{
    transform: scale(1.05);
    transition: .3s linear;
    background: linear-gradient(180deg, #F24060 0%, #D90917 100%);
opacity: 0.9;
}
.subs .col-md-12 .col-md-3.package p{
    font-size: 18px;
    padding: 0 0 10px 0;
    color: #fff;
    border-bottom: 3px solid #ccc;
}
.subs .col-md-12 .col-md-3.package p.per{
    padding: 0px 0;
    font-size: 34px;
    border-bottom: none;
}
.subs .col-md-12 .col-md-3.package p.per img{
    margin: -12px 0 0 0;
    padding: 0;
}
.subs .col-md-12 .col-md-3.package li{
    text-align: left;
    padding: 20px 0% 10px 10px;
    font-size: 17px;
}

.subs .col-md-12 .col-md-3.package .fa-check{
    color: #FFFFFF;
    font-size: 16px;
    display: none;
}
.subs .col-md-3 li{
    text-align: left;
    color:#fff;
    list-style-type: none;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 20px;
    padding: 30px 0% 0px 0px;
    font-family: 'Futura PT';
}
.subs .col-md-3 li .fa{
color: #D90917;
padding: 0px 5px 0px 0px;
}
.subs .col-md-3 .btn_buy{
    margin: 10px 5%;
    color: #D90917;
    background-color: #fff;
    font-size: 16px;
    border-radius: 4px;
    text-align: center;
    padding: 10px 20px;
    width: 90%;
}

/*content details css for 1000px ipad air ipad mini tab*/
.getuser .container img#user{
    width: 14%;
    height: 85px;
    margin: 10px 43%;
    padding: 10px;
    border:1px solid #D90917;
    border-radius: 50%;
    position: relative;
} 


    /*css for ipad loginscreen*/
    .login .logg .otp{
        display: inline-flex;
        width: 70%;
        margin: 10px 5%;
        border:1px solid #ccc;
    }
    .login .logg{
        width: 70%;
        border-radius: 12px;
        margin: 5% 15%;
        padding: 20px 10px;
        display: inline-block;
        height: auto;
        border: 2px solid #D90917;
    }
    .login .logg .social-icons{
        width: 30%;
        display: inline-flex;
        margin: 10px 35%;
    }
    ul.quick_link{
        padding: 0 0px;
        margin: 0;
        list-style-type: none;
        width: 100%;
        display: inline-block;
    }
    ul.quick_link li {
        width: 100%;
        display: inline-block;
        padding: 0;
        margin: 5px 0px 0px 0;
    }
    ul.quick_link li a{
        width: 100%;
        display: inline-block;
        font-size: 20px;
        font-weight: normal;
        color: #fff;
        transition: all ease-in-out 0.3s;
    }
    /*css for ipad for header*/
    header {
        width: 100%;
        position: relative;
        height: 70px;
        background: transparent;
        top: 0;
        display: inline-flex;
        padding: 10px 0;
    }
    .header_btn {
        float: right;
        margin-top: 0px;
        padding: 0 0px 0px 0px;
        margin-right: 0px;
        margin-left: 55%;
    }
    .header_btn ul li a{
        font-size: 28px;
    }
    .header_btn ul li a .fa{
        font-size: 22px;
    }

    header .right_nav{
        display: inline-flex;
        width: 100%;
        padding: 0;
        border-bottom: 1px solid red;
    }
    header .right_nav .mobile_primary{
        width: 40px;
        right: 0;
        height: 40px;
        margin:10px 0 0 0px;
        padding: 0;
        overflow: hidden;
    }
    header .right_nav .mobile_primary img{
        width: 30px;
        height: 30px;
        padding: 0 0 0 0px;
    }
    header .right_nav .mobile_primary ul{
        display: none;
    }
    header .right_nav .mobile_primary ul.active{
        width: 100%;
        height: 100vh;
        z-index: 999999;
        left:0;
        top:0;
        background-color: #D8000F;
        position: absolute;
        display: inline-block;
    }
    header .right_nav .mobile_primary ul.active img{
        right: 0;
        position: absolute;
        top:0;
        margin: 10px;

    }
    header .right_nav .mobile_primary ul.active li a{
        outline: none;
        display: block;
        font-size: 36px;
        width: 80%;
        margin: 0 20% 0 0;
        color:#fff;
        padding: 25px 0 0 25px;
        text-decoration: none;
    }

    .header .right_nav a{
       display: none;
    }
    .frequent{
        padding: 20px 0%;
        width: 100%;
        height: auto;
        background-color: transparent;
        align-content: center;
        justify-content: center;
        margin:0;
    }
    .frequent h1{
        text-align: center;
        color: #fff;
        padding: 10px;
    }
    .frequent h3{
        text-align: center;
        color: #fff;
        padding: 10px;
        margin-bottom: 40px;
    }
    .frequent .row{
        padding: 0px;
    }
    .frequent .row .col-md-4 {
        padding: 0px;
    }
    .frequent .row .col-md-4 .ask{
        width: 98%;
        margin:20px 1% 10px 1%;
        background-image: url('./assets/Icons/Rectangle\ 474.png');
        background-size: cover;
        padding: 10% 10px;
        border-radius: 12px;
        height: 300px;
    }
    .frequent .row .col-md-4 .ask p{
        color: #ccc;
        padding: 0;
    }
    .frequent .row .col-md-4 .ask h1{
        padding: 10px 0;
        font-size: 26px;
        text-align: left;
    }
    .frequent .row .col-md-4 .ask .playbtn{
        width: 60%;
        margin: 20px 35% 10px 0%;
        color :#fff;
        background-color: #D90917;
        clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
        padding: 13px 0px;
    }
    .frequent .row .col-md-8{
        padding: 10px;
        display: inline-block;
        margin: 0px 0%;    
    }
    .frequent .row .col-md-8 .freq-child{
        display: inline-flex;
        width: 90%;
        padding: 0;
        margin: 15px 5% 0 5%;
    }
    
    .frequent .row .col-md-8 .hidden{
        height: 0px;
        width: 90%;
        padding: 0px;
        margin: 0px 5% 0 5%;
        color:#ccc;
        overflow: hidden;
        transition: .3s linear;
        background-color:rgba(208, 8, 32, 0.1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .frequent .row .col-md-8 .hidden p{
    padding: 10px 15px;
    color: #ccc;
    }
    .frequent .row .col-md-8 span{
        width: 90%;
        color: #fff;
        padding: 13px 20px;
        background-image: url('./assets/Icons/Rectangle\ 28.png');
        background-size: cover;
    }
    .container.add{
        width: 100%;    
        margin: 20px 0px 20px 0px;
        background-color: #070101;
        padding: 0;
    }
    .container.add .col-md-6:nth-child(1){
        width: 50%;
        height: 100%;
        margin:0 -8% 0 0;
        padding: 0px 0 0 0px;
        z-index: 9999;
    }
    .container.add .col-md-6:nth-child(1) img{
        width: 95%;
        height: auto;
        padding: 0;
        margin: 0 0% 0 5%;
    }
    .container.add .col-md-6:nth-child(2){
    display: inline-block;
    background-image: url('./assets/Icons/Rectangle\ 5.png');
    background-size: cover;
    padding: 0px 0px 40px 6%;
    height:auto;
    margin:4% 0;
    z-index: 1;
    }
    
    .container.add .col-md-6 h2{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #FFFFFF;
    padding: 10px 0px 0px 0px;
    float: left;
    }
    .add .col-md-6 p{
        color: #fff;
        margin: 60px 0px 0px 0px;
        padding: 10px 0px;
        font-size: 16px;
        font-weight: normal;
    }
    .add .col-md-6 h3{
        color: #fff;
        margin: 0px 0px 0px 0px;
        padding: 10px 0px;
        font-size: 22px;
    }
    .add .col-md-6 .images a img{
        padding: 0;
        margin: 0 10px 0px 0px;
        width: 170px;
        height:auto;
    }
/*css for ipad home slider*/
.banner .banner_col1 {
    height: auto;
    width: 100%;
    padding:0;
    margin: 0;
}
.banner .banner_col1 img{
    width: 100%;
    margin: 10px 0;
}
.banner .banner_col1 .banner_btm{
    display: inline-flex;
    width: 100%;
    height: 200px;
    margin: 10px 0;
}
.banner .banner_col1 .banner_btm h2{
    color: #fff;
    padding: 10px 0 10px 10px;
    margin: 0px 0 0 0;
    width: 70%;
    font-size: 28px;
}
.banner .banner_col1 .banner_btm a{
    width: 30%;
    margin: 0;
}
.banner .banner_col1 .banner_btm a img{
    width: 99%;
    margin: -60px 1% 0 0%;
    padding: 0;
}
.banner .banner_col1 .homeslider{
    width: 100%;
    margin: 0 0% 0 0%;
    display: inline-flex;    
}

.banner .banner_col1 .homeslider .detailed{
    width: 45%;
    padding: 10px 0px 20px 1%;
    margin: 0 0 0 0%;
}
.banner .banner_col1 .homeslider .detailed p{
    color: #ccc;
    font-size:16px;
    padding: 10px 0 20px 0;
}
.banner .banner_col1 .homeslider .detailed h2{
    color: #fff;
    font-size: 30px;
    padding: 0px 0 0 0;
}
.banner .banner_col1 .homeslider .detailed .head{
    font-size: 20px;
    padding: 0;
}
.banner .banner_col1 .detailed .head span{
    font-size: 22px;
    color:#D8000F;
}
.banner .banner_col1 .detailed .buttons{
    display: flex;
    padding: 0;
}
.banner .banner_col1 .detailed .buttons .fa{
    font-size: 14px;
    padding: 8px 10px;
    color: #D8000F;
    margin: 0 10px 0 0;
    border-radius: 2px;
    opacity: 1;
    background-color: #fff;
}
.banner .banner_col1 .detailed .buttons .playbtn{
    background-color: #D8000F;
    display: inline-flex;
    border-radius: 2px;
    padding: 8px 0px 8px 8px;
    height: 55px;
    width: 35%;
    margin:0 10px 0 0;
    clip-path: polygon(0 0,100% 0,95% 100%, 0 100%);
}
.banner .banner_col1 .detailed .buttons span{
    padding: 5px 0 0 0;
    font-size: 14px;
}
.banner .banner_col1 .detailed .buttons .watchbtn{
    display: inline-flex;
    padding: 8px 0px 8px 8px;
    height: 55px;
    width:63%;
    background-color: transparent;
    background-image: url('./assets/Icons/Rectangle\ 1214\ \(1\).png');
    background-size: cover;
    margin:0 0px 0 0;
    
}
.banner .banner_col1 .homeslider .swiper {
    padding: 0px 10px 0px 40px;
    margin: 0 0% 0 0%;
    width:55%;
    overflow:hidden;
    height:360px;
    display: flex;
}  
.banner .banner_col1 .swiper-slide {
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  width: 100%;
  height: 300px;
  background-size: cover;
  }
  .banner .banner_col1 .swiper-slide img{
    width: 95%;
    height: 100%;
    border-radius: 12px;
}
.banner .banner_col1 .homeslider .bottom{
    top:35%;
    padding: 15px 0px;
    width: 38%;
    margin: 0 0 0 48%;
    position: absolute;
    display: inline-flex;
}
.banner .banner_col1 .bottom p{
    font-size: 18px;
    width: 20%;
    display: inline-flex;
}
.banner .banner_col1 .bottom .line{
    width: 75%;
    height: 2px;
    margin: 15px 0;
    background-color: #fff;
}
 .banner .swiper-button-next::after {
    display: none;
  }
  .banner .swiper-button-prev::after {
    display: none;
  }

  .banner .banner_col1  .swiper-button-prev{
    position: absolute;
    background-image: url('./assets/Icons/Left arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px;
    width:30px;
    top:95%;
    left:80%;
    padding: 10px; 
    overflow:hidden;
  }
  .banner .banner_col1 .swiper-button-next{
    position: absolute;
    background-image: url('./assets/Icons/right arrow.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px;
    width:30px;
    top:95%;
    right:4%;
    padding: 10px; 
    overflow:hidden;
  }
.trailer h1{
    color: #fff;
    font-size: 24px;
    padding: 0% 0px 0px 0px;
    position: absolute;
}
.trailer p{
    padding: 3% 0 0 0;
    color: #ccc;
    font-size: 18px;
    position: absolute;
}
    .content_popup{
        background-color: #111;
        width: 70%;
        height: auto;
        align-content: center;
        justify-content: center;
        margin: 30% 15%;
        color: #fff;
        padding: 20px;
        border-radius: 12px;
        position: absolute;
        z-index: 999999999;
    }
    .deep_handle{
        width:100%;
        width: 100%;
        height: 150px;
        padding: 10px;
        bottom: 0;
        display: none;
    }
    .deep_handle .install{
        border-radius: 0;
        width: 100%;
        max-width: 100%;
        bottom: 0;
        height: 140px;
        min-height: 140px;
        color: #fff;
        padding: 10px 5px;
        background-color: #000;
        display: inline-flex;
        position: fixed;
        overflow: hidden;
        }
    .login{
        padding: 0;
        margin:0;
    }
    .banner .banner_col{
        height:auto;
        width:100%;
        overflow: hidden;
        padding: 10px 0;
        margin:0px 0px;
    }
    .banner .banner_col .swiper-slide{
        color:#fff;
        background: transparent;
        margin: 60px 0;
        height: 130px;
        opacity: 1;
        transition: .3s linear;
      }
      .banner .banner_col  .swiper-button-prev{
        position: absolute;
        background-image: url('./assets/Icons/Left arrow.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        height: 30px;
        width:30px;
        top:12%;
        left:89%;
        padding: 10px; 
        overflow:hidden;
      }
      .banner .banner_col .swiper-button-next{
        position: absolute;
        background-image: url('./assets/Icons/right arrow.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        height: 30px;
        width:30px;
        top:12%;
        right:1%;
        padding: 10px; 
        overflow:hidden;
      }
    .product_category_heading span{
        width: 15%;
    }
    footer {
        width: 100%;
        display: inline-block;
        margin:20px 0% 0 0%;
        padding: 10px 0 0 0;
    }
    .footer_logo {
        width: 200px;
        margin-bottom:20px;
        margin-top: 30px;
    }
    ul.social_icon{
        display: inline-flex;
    }
    ul.social_icon li {
        float: left;
        padding: 10px 0;
        margin: 0px 0px 0 10px; 
    }
    .toggle_search_open {
        width: 100%;
        height: 100vh;
        background-color: #D90917f2;
        position: fixed;
        top: 0;
        left: 0;
        padding: 20px 0px;
        z-index: 99999999;
    }
}



/*written for landcape mode for pixel screens and iphones*/
@media screen and (max-height: 420px) {
      /*css for landscape player*/
      header {
        width: 100%;
        position: relative;
        height: 70px;
        background: transparent;
        top: 0;
        display: inline-flex;
        padding: 10px 0;
    }
    .header_btn {
        float: right;
        margin-top: 0px;
        padding: 0 0px 0px 0px;
        margin-right: 0px;
        margin-left: 55%;
    }
    .header_btn ul li a{
        font-size: 28px;
    }
    .header_btn ul li a .fa{
        font-size: 22px;
    }

    header .right_nav{
        display: inline-flex;
        width: 100%;
        padding: 0;
        border-bottom: 1px solid transparent;
    }
    header .right_nav .mobile_primary{
        width: 40px;
        right: 0;
        height: 40px;
        margin:10px 0px 0 0px;
        padding: 0;
        overflow: hidden;
    }
    .getuser .container img#user{
        width: 20%;
        height: 90px;
        margin: 10px 40%;
        padding: 10px;
        border:1px solid #D90917;
        border-radius: 50%;
        position: relative;
    } 
    .content_popup{
        background-color: #111;
        width: 60%;
        height: auto;
        align-content: center;
        justify-content: center;
        margin: 5% 20%;
        color: #fff;
        padding: 20px;
        border-radius: 12px;
        position: absolute;
        z-index: 999999999;
    }
    .content_popup h2{
        color:#D90917;
        font-size: 30px;
        text-align: center;
        margin: 20px 0px;
    }
    .content_popup p{
    color: #ccc;
    text-align: center;
    width: 90%;
    font-size: 16px;
    margin: 0px 5% 10px 5%;
    }
    .content_popup p.head{
        color: #fff;
        font-size: 19px;
        width: 95%;
        margin: 0px .5%;
    }
    .content_popup .buttons{
        display: inline-flex;
        width: auto;
        margin: 20px 5%;
        width:90%;
    }

    .banner .banner_col:nth-child(2) .swiper-slide {
        height: 160px;
        background: transparent;
        opacity: .7;
        margin: 5% 0px 0px 0;
        border-radius: 12px;
      }
      .banner .banner_col:nth-child(2) .swiper-slide-active {
        color:#fff;
        background: transparent;
        margin: 50px 0;
        transform: scale(1.5);
        z-index: 99;
        height: 140px;
        opacity: 1;
        transition: .3s linear;
      }
    .banner .banner_col{
        height:420px;
        width:100%;
        overflow: hidden;
        padding: 0;
        margin:0px 0px;
    }
    .banner .banner_col .swiper-slide{
        color:#fff;
        background: transparent;
        margin: 50px 0;
        height: 250px;
        opacity: 1;
        transition: .3s linear;
      }
    .banner .banner_col .swiper-slide-active {
        color:#fff;
        background: transparent;
        margin: 110px 0;
        transform: scale(2.3);
        z-index: 99;
        height: 150px;
        opacity: 1;
        transition: .3s linear;
    }
    .banner .banner_col  .swiper-button-prev, .banner .banner_col  .swiper-button-next{
        padding: 10px;
        overflow:hidden;
       color: #D90917;
       margin: 170px 40% 10px 40%;
       z-index: 99999999;
       font-size: 12px;
    }
    .banner .banner_col #play_btn{
        width: 8%;
        height:auto;
        margin: -50px 46% 0px 46%;
        z-index: 1;
        opacity: 1;
        position: absolute;
    }
    .banner .banner_col #play_btn_trailer{
        width: 50px;
        height:50px;
        margin-left: 47%;
        margin-top: -6%;
        z-index: 999999;
        opacity: 1;
        position: absolute;
    }
}

@media  screen and(max-height : 380px) {
    .content_popup h2{
        color:#D90917;
        font-size: 28px;
        text-align: center;
        margin: 10px 0px;
    }
    .content_popup p{
    color: #ccc;
    text-align: center;
    width: 90%;
    font-size: 15px;
    margin: 0px 5% 10px 5%;
    }
    .content_popup p.head{
        color: #fff;
        font-size: 18px;
        width: 95%;
        margin: 0px .5%;
    }
   
}